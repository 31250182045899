import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useLocation
} from "react-router-dom";
import {
    List, Datagrid, TextField, DateField,SelectField,
    ReferenceField, ImageField, EmailField, Filter, TextInput, ReferenceInput, AutocompleteInput,
    NumberField, BooleanField, EditButton,SelectInput
} from 'react-admin';
import queryString from 'query-string';
import { ListActions } from "./ListActions";

const EmployeeFilter = props => (
    <Filter {...props} variant="outlined">
        <TextInput variant="outlined" label="EMP Code" source="empCode~like" alwaysOn />
        <TextInput variant="outlined" label="Firstname" source="firstName~like" alwaysOn />
        {/* <TextInput variant="outlined" label="Lastname" source="lastName~like" alwaysOn /> */}
        <TextInput variant="outlined" label="Email" source="email~like" alwaysOn />
        <TextInput variant="outlined" label="Mobile" source="mobile~like" alwaysOn />
        <ReferenceInput 
            source="subjectId"
            perPage={2500}
            reference="subjects"
            sort={{ field: 'name', order: 'ASC' }}
        >
            <SelectInput source="name" />
        </ReferenceInput>
    </Filter>
);



export const EmployeeList = props => {
    let propsObj = { ...props };
    let location = useLocation();

    const values = queryString.parse(location.search);
    let usertype = (values.type) ? values.type : 0;

    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      //let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      let myPermissions= propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() === "/employees?type="+usertype);
      propsObj.hasList = false;
      propsObj.hasShow = false;
      propsObj.hasEdit = false;
      propsObj.hasDelete =false;
      propsObj.hasCreate =false;
      propsObj.hasExport = false;
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }
    localStorage.setItem("employeeCustom","/employees?type="+usertype) ;  
    const savedDisplayAccountType = localStorage.getItem("displayEmployeeType");
    if (!values.type && savedDisplayAccountType != undefined) {
        usertype = savedDisplayAccountType;
    }
    localStorage.setItem("displayEmployeeType", usertype);

    var loggedInuserType = localStorage.getItem("type");
    var Ownrecords = localStorage.getItem("Ownrecords");
    var userId = localStorage.getItem("userId");
    var filter = {};
    let hasCreate = false;
    if (usertype != 0) {
        filter = { empRoleTypeId: usertype };
        hasCreate = true;
    }
    if (Ownrecords == "true") {
        filter.id = userId;
    } else {
        filter['id~neq'] = 2;
    }

    return (
        <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false}  filter={filter} filters={<EmployeeFilter />} actions={<ListActions hasCreate={propsObj.hasCreate} />}>
            <Datagrid rowClick={'edit'}>
            {propsObj.hasEdit && <EditButton />}
            {propsObj.hasEdit===false && propsObj.hasShow===true && <EditButton label={"Show"} />}
                <TextField source="empCode" label="Emp Code" className="employee-code" formClassName="employee-code" />
                <BooleanField source="isActive" label="Is Active" />
                <TextField source="firstName"  label="First Name" />
                <TextField source="middleName" label="Middle Name"/>
                <TextField source="lastName" label="Last Name"/>
                <DateField source="doj" label="DOJ" locales="fr-FR"/>
                <ReferenceField link={false} source="designationId" reference="designations"><TextField source="name" /></ReferenceField>
                <ReferenceField link={false} source="subjectId"  label="Subject" reference="subjects"><TextField source="name" /></ReferenceField>
                
                {/* <ReferenceField link={false} source="departmentId" reference="departments"><TextField source="name" /></ReferenceField> */}
                <SelectField source="empRoleTypeId" label="Employment" choices={[
                    { id: 1, name: 'On-Roll' },
                    { id: 3, name: 'Freelancer' },
                    { id: 4, name: 'Contractual' },
                ]} />
                <EmailField source="email" label="Email ID"/>
                <TextField source="mobile" label="Mobile Number"/>
                <TextField source="skypeId" reference="skypes"></TextField>
                <DateField source="entryDate"   locales="fr-FR"/>
                <ReferenceField link={false} source="status" reference="statuses"><TextField source="status" /></ReferenceField>
            </Datagrid>
        </List>
    )
};
import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "./formStyles";

export const AddressFields = ({ formData,
    countryField,
    stateField,
    cityField,
    countryLabel,
    stateLabel,
    cityLabel
    , ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
    return (
        <div className="address-field-parent">
            <ReferenceInput variant="outlined" label={countryLabel} source={countryField} reference="countries"
                onChange={(v) => {
                    console.log(v);
                    form.change(stateField, 0);
                    form.change(cityField, 0);
                }}
                sort={{ field: 'countryName', order: 'ASC' }}
                fullWidth={true} perPage={500}>
                <SelectInput optionText="countryName" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label={stateLabel} source={stateField}
                reference="states" perPage={500}
                filter={{ countryId: (formData[countryField]) ? formData[countryField] : 0 }}
                onChange={(v) => {
                    //console.log(v);
                    form.change(cityField, 0);
                }}
                sort={{ field: 'stateName', order: 'ASC' }}
                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                fullWidth={true} className={classes.two_three_input}>
                <SelectInput optionText="stateName" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label={cityLabel}
                filter={{ statesId: (formData[stateField]) ? formData[stateField] : 0 }}
                sort={{ field: 'cityName', order: 'ASC' }}
                validate={required()} source={cityField} reference="cities" perPage={500}
                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                fullWidth={true} className={classes.last_three_input}>
                <SelectInput optionText="cityName" />
            </ReferenceInput>
        </div>
    )
}

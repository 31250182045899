import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function HolidayList({data}) {
    const classes = useStyles();
    return (
        <Table className={classes.table} aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="right">Date</StyledTableCell>
                    <StyledTableCell align="right">Description</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row,index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                            {row.Name} 
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.Date}</StyledTableCell>
                        <StyledTableCell align="right">{row.Description}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
}

import React, {useEffect, useCallback,useState } from 'react';
import {
    required,
    useNotify,
    useRedirect,
    minLength,
    maxLength,
    minValue,
    ReferenceField,
    TextField,
    DateField,
    NumberField,
    ReferenceManyField,
    Datagrid,
    FormTab,
    AutocompleteInput,
    Edit, TabbedForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { EmployeeList } from './EmployeeList'
import Button from '@material-ui/core/Button';
import { Drawer } from '@material-ui/core';
import { EmployeeFields } from './EmployeeFields';
const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: "90%",
    },
}));

export const NotificationEdit = props => {
    const classes = useStyles({});
    const myclasses = myStyles({});
    const [isAddResourceOpen, setIsAddResourceOpen] = useState(false);
    const [cols, setCols] = useState([]);
    const [griddata, setData] = useState([]);


    const onCancel = () => {
        setIsAddResourceOpen(false); 
    }
    const onCloseForm = () => {
        setIsAddResourceOpen(false);  
        loadNotificationDetail();
    }
    useEffect(() => {
        loadNotificationDetail()
    }, []);
    const loadNotificationDetail = async () => {
        
        let formData = {
            notificationId: props.id, 
        }
        
        apiClient.getNotificationdetail(formData).then((res) => {
            setData(res);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                let data = [];
                for (let index = 0; index < keys.length; index++) {
                    if (keys[index] != "NotificationDetID") {
                        data.push(keys[index])
                    }
                }
                setCols(data);
            }
            
        }
        )
    }


    return (
        <React.Fragment>
            <Edit {...props} title="Notification Create" redirect={false} successMessage="Notifiacton Updated" undoable={false}>
                <TabbedForm redirect="list" variant="outlined" >
                    <FormTab value="DETAILS" label="Details">
                        <DateInput source="date" label="Date" fullWidth={true} formClassName={classes.one_three_input} disabled />
                        <ReferenceInput validate={required()} label="For Project" source="projectId" reference="work-role-projects" fullWidth={true} formClassName={classes.two_three_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <TextInput source="notiSubject" label="Notification Subject" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
                        <DateInput source="startDate" label="Project Start Date" fullWidth={true} formClassName={classes.one_three_input} validate={required()} />
                        <DateInput source="endDate" label="Project End Date" fullWidth={true} formClassName={classes.two_three_input} validate={required()} />
                        <DateInput source="lastRespDate" label="Last Receiving Date" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
                        <TextInput source="notiDetails" label="Notification Detail" fullWidth={true} validate={required()} />
                        <NumberInput source="totalResource" label="No Of Resources" fullWidth={true} formClassName={classes.one_three_input} validate={required()} />
                        {/* <ReferenceInput validate={required()} label="Team Lead" source="teamLeadId" reference="employees" fullWidth={true} formClassName={classes.two_three_input}>
                            <SelectInput optionText="firstName" />
                        </ReferenceInput> */}
                        <ReferenceInput
              perPage={25} sort={{ field: 'firstName', order: 'ASC' }}
              filterToQuery={searchText => ({ 'firstName~like': searchText })}
              validate={[required()]} label="Team Lead" source="teamLeadId" reference="employees" fullWidth={true} formClassName={classes.two_three_input}>
              {/* <AutocompleteInput optionText="firstName" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} /> */}
              <AutocompleteInput
                    optionText={choice =>
                        `${choice.firstName} ${choice.lastName} (${choice.empCode}) `
                    }
                />
            </ReferenceInput>

                        <BooleanInput source="isActive" label="Is Active" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
                    </FormTab>
                    <FormTab value="Other" label="Other">
                        <Button variant="contained" onClick={() => setIsAddResourceOpen(true)}
                            color="secondary">
                            Add Resources
                            </Button>   
                            <EmployeeList data={griddata} columns={cols} />
                         
                    </FormTab>


                </TabbedForm>

            </Edit>
            <Drawer anchor="right" onClose={onCancel} classes={{
                    paper: myclasses.drawerPaper,
                }}
                open={isAddResourceOpen}>
                    {isAddResourceOpen &&
                    <EmployeeFields
                            
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        notificationId={props.id}
                        {...props} />}
            </Drawer>



        </React.Fragment>);
}

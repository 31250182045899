import React from 'react';

import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField,Filter,TextInput,ReferenceInput,AutocompleteInput
}
    from 'react-admin';
const TestFilter = props => (
        <Filter variant="outlined" {...props}>
            
            <ReferenceInput label="Project" variant="outlined"  source="projectId" reference="work-role-projects" alwaysOn>
                <AutocompleteInput
                    optionText={choice => {
                        return (choice.name) ? choice.name : "All";
                    }
                    }
                />
            </ReferenceInput>
            <TextInput variant="outlined"  label="Subject" source="notiSubject~like" alwaysOn />
        </Filter>
    );
export const NotificationList = props => {
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }
    return (
        <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} filters={<TestFilter />} filter={parseInt(localStorage.RoleTypeId)===11?null:{ 'createdBy': parseInt(localStorage.userId) } } >
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}> 
        {propsObj.hasEdit && <EditButton />}
        
            <DateField source="date" label="Date"/> 
            <ReferenceField  label="For Project" source="projectId" reference="work-role-projects" link={false}>
                    <TextField source="name" />
            </ReferenceField>
            <DateField  source="startDate" label="Project Start Date"/>
            <DateField  source="endDate" label="Project End Date"/>
            <DateField  source="lastRespDate" label="Last Receiving Date"/>
            <TextField source="notiSubject" label="Notification Subject" />
            <TextField source="notiDetails" label="Notification Detail" />
            <TextField source="totalResource" label="Total Resource Required" />
            <TextField source="totalSent" label="Total Notification Sent" />
            <TextField source="totalAccepted" label="Total Notification Accepted" />
            <TextField source="totalRejected" label="Total Notification Rejected" />
            <TextField source="totalPending" label="Total Notification Pending" />
            <ReferenceField source="teamLeadId" reference="employees" label="Team Lead"  link={false}>
                <TextField source="firstName" />
            </ReferenceField>
            <ReferenceField source="createdBy" reference="employees" label="Created By"  link={false}>
                <TextField source="firstName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date"/>
            <ReferenceField source="modifyBy" reference="employees" label="Modified By"  link={false}>
                <TextField source="firstName" />
            </ReferenceField>
            <DateField  source="modifyDate" label="Modified Date"/>
        </Datagrid>
    </List>
)};
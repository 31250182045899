import { Button, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import * as apiClient from "../../apiClient";
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField, useNotify,

} from 'react-admin';
import { useStyles } from "../../formStyles";
import { MultipleSelect } from "react-select-material-ui";

export const EmployeeFields = ({ formData, ...rest }) => {
    const notify = useNotify();
    const [empId, setEmpId] = useState("")
    const [name, setName] = useState("")
    const [emailId, setEmailId] = useState("")
    const [triviumemailId, setTriviumemailId] = useState("")
    const [employmentType, setEmploymentType] = useState(0)
    const [cols, setCols] = useState([]);
    const [griddata, setData] = useState([]);
    const [fetching, setFetching] = useState(false);  
    const [verticalId, setVerticalId] = useState(0)
    const [businessId, setBusinessId] = useState(0)

    const [tags, setTags] = useState([])
    const [options, setOptions] = useState([])
    const [subjects, setSubjects] = useState([])
    const [selectedsubjects, setSelectedSubjects] = useState([])

    const [departmenttags, setDepartmentTags] = useState([])
    const [departmentoptions, setDeparmentOptions] = useState([])
    const [departments, setDepartments] = useState([])
    const [selecteddepartments, setSelectedDeparments] = useState([])

    const handleChange = async (value) => {

        let data = [...tags];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedSubjects(data[data.length - 1])
        }
        if (value == null) {
            setSelectedSubjects([])
        }
        setTags([...data])
    };

    const handleDeparmentChange = async (value) => {
        let data = [...departmenttags];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedDeparments(data[data.length - 1])
        }
        if (value == null) {
            setSelectedDeparments([])
        }
        setDepartmentTags([...data])
    };



    useEffect(() => {
        loadSubjects()
        loadDeparment();
    }, []);
    const loadSubjects = async () => {
        apiClient.GetAllSubjects().then((res) => {
            let data = [];
            let dataoption = [];
            setSubjects(res);
            data = res;
            data.map(item => dataoption.push(item.name));
            setOptions(dataoption);
        }
        )
    }
    const handleSelectAll = async (e) => {
        let data = [...griddata];
        for (let index = 0; index < data.length; index++) {
            data[index].selected = e.target.checked;
        }
        setData(data);
    }
    const handleSelect = async (e, rowIndex) => {
        let data = [...griddata];
        data[rowIndex].selected = e.target.checked;
        setData(data);
    }
    const handleSubmit = async () => {
       
        let data = [...griddata];
        let selectedData = []
        selectedData = data.filter(x => x.selected === true);
        if (selectedData === undefined || selectedData.length === 0) {
            alert("Please select Resource to save");
            return;
        }
        let formData = [];
        for (let index = 0; index < selectedData.length; index++) {
            formData.push({ id: 0, NotificationID: rest.notificationId, EmployeeID: selectedData[index].EmployeeID })
        }
        apiClient.addResource({ data: formData }).then((res) => {
            if (res[0].result === true) {
                notify(res[0].message);
                rest.onClose();
            }
            else { alert(res[0].message); }
        }
        )

    }
    const searchResource = async () => {
        let _departments = [...selecteddepartments]
        let _subjects = [...selectedsubjects]
        let subjectstr = "";
        let deparmentstr = "";

        for (let index = 0; index < _subjects.length; index++) {
            let s = _subjects[index];
            let subjectItem = subjects.filter(x => x.name === s);
            if (subjectItem != undefined && subjectItem != null && subjectItem.length > 0) {
                if (subjectstr.length === 0) {
                    subjectstr = subjectItem[0].id;
                }
                else {
                    subjectstr = `${subjectstr},${subjectItem[0].id}`
                }
            }
        }
        for (let index = 0; index < _departments.length; index++) {
            let s = _departments[index];
            let departmentItem = departments.filter(x => x.name === s);
            if (departmentItem != undefined && departmentItem != null && departmentItem.length > 0) {
                if (deparmentstr.length === 0) {
                    deparmentstr = departmentItem[0].id;
                }
                else {
                    deparmentstr = `${deparmentstr},${departmentItem[0].id}`
                }
            }
        }
        let formData = {
            NotificationID: rest.notificationId,
            EmpCode: empId,
            EmpName: name,
            Email: emailId,
            MobileNo: '',
            DepartmentID: deparmentstr,
            WorkRoleID: verticalId,
            WorkRoleProjectID: businessId,
            SubjectID: subjectstr,
            ROEmail: triviumemailId,
            EmployeRoleTypeID: employmentType
        }
        setFetching(true);
        apiClient.searchResource(formData).then((res) => {
            setData(res);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                let data = [];
                for (let index = 0; index < keys.length; index++) {
                    if (keys[index] != "EmployeeID") {
                        data.push(keys[index])
                    }
                }
                setCols(data);
            }
            setFetching(false);
        }
        )
    }
    const loadDeparment = async () => {
        apiClient.getDepartments().then((res) => {
            let data = [];
            let dataoption = [];
            setDepartments(res);
            data = res;
            data.map(item => dataoption.push(item.name));
            setDeparmentOptions(dataoption);

        }
        )
    }

    const classes = useStyles({});
    return (
        <SimpleForm variant="outlined" toolbar={null}  >
            <div><span style={{fontSize:"22"}}>Search Employee</span></div>
            <TextInput source="empID" label="Emp ID " fullWidth={true} variant="outlined" formClassName={classes.one_4_input} onChange={(e) => { setEmpId(e.target.value); }} />
            <TextInput source="name" label="Name " fullWidth={true} variant="outlined" formClassName={classes.two_4_input} onChange={(e) => { setName(e.target.value); }} />
            <TextInput source="emailId" label="EmailId" fullWidth={true} variant="outlined" formClassName={classes.three_4_input} onChange={(e) => { setEmailId(e.target.value); }} />
            <TextInput source="triviumemailId" label="Trivium Email " fullWidth={true} variant="outlined" formClassName={classes.last_4_input} onChange={(e) => { setTriviumemailId(e.target.value); }} />
            <SelectInput source="employmentType" label="Employment Type" fullWidth={true} variant="outlined" formClassName={classes.one_4_input} onChange={(e) => { setEmploymentType(e.target.value); }} choices={[
                { id: '', name: '' },
                { id: '1', name: 'On Roll' },
                { id: '3', name: 'Freelancer' },
                { id: '4', name: 'Contractual' },
            ]}
            />
            
            <ReferenceInput validate={required()} label="Vertical" source="workroleId" reference="work-roles" variant="outlined" fullWidth={true} formClassName={classes.two_4_input} onChange={(event)=>{setVerticalId(event.target.value)}} >
                <SelectInput optionText="name" fullWidth={true} formClassName={classes.two_4_input}   /> 
            </ReferenceInput>
            <ReferenceInput validate={required()} label="Business" source="businessId" reference="work-role-projects" variant="outlined" fullWidth={true} formClassName={classes.three_4_input} onChange={(event)=>{setBusinessId(event.target.value)}}  >
                <SelectInput optionText="name" fullWidth={true} formClassName={classes.three_4_input} />
            </ReferenceInput> 
            <div style={{ width: "33%", float: "left" }}>
                <MultipleSelect label="Choose Department" values={departmenttags} options={departmentoptions}
                    helperText="You can search multiple Department" onChange={handleDeparmentChange}
                    SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All Departments are selected", msgNoOptionsMatchFilter: "No Department name matches the filter", }} />
            </div>
            <div style={{ width: "33%", float: "left" }}>
                <MultipleSelect label="Choose Subject" values={tags} options={options}
                    helperText="You can search multiple Subject" onChange={handleChange}
                    SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All Subjects are selected", msgNoOptionsMatchFilter: "No Subject name matches the filter", }} />
            </div>
            <div style={{ width: "34%", float: "left", marginTop: "13px", display: "flex" }}>
                <div style={{ marginRight: "10px" }} ><Button variant="contained" color="primary" style={{ leftMargin: "10px" }} onClick={searchResource}  >Search</Button></div>
                <div style={{ marginRight: "10px" }}><Button variant="contained" color="primary" style={{ leftMargin: "10px" }} onClick={handleSubmit} >Save</Button></div>
                <div style={{ marginRight: "10px" }}><Button variant="contained" color="primary" style={{ leftMargin: "10px" }} onClick={() => rest.onCancel()} >Back</Button></div>

            </div>
            {fetching && <div ><CircularProgress /></div>}
            {griddata && griddata.length > 0 && <div >
                <table   className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny font-size-12">
                    <tr>
                        <th key={0}><input type="checkbox" onChange={(e) => handleSelectAll(e)} /> </th>
                        {cols && cols.map((item, index) => {
                            return (
                                <th key={item}>{item}</th>
                            )
                        })}
                    </tr>

                    {griddata && griddata.map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex}>

                                <td key={0}><input type="checkbox" checked={row.selected != undefined && row.selected != null ? row.selected : false} onChange={(e) => handleSelect(e, rowIndex)} /> </td>
                                {cols && cols.map((col, colIndex) => {
                                    return (
                                        <td key={rowIndex + "-" + colIndex}>
                                            {row[col]}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </table>
            </div>
            }

        </SimpleForm>

    )
}

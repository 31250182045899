import React, { useCallback } from 'react';
import {
    required,
    useNotify,
  useRedirect,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
	AutocompleteInput,
    Create, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import { useStyles } from "../../formStyles";  
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";

export const NotificationCreate = props => {
    const classes = useStyles({});
    const notify = useNotify();
    const redirect = useRedirect();
    const initialValues = {
        date: new Date(),
        
    
      }
      const handleSubmit = (formdata) => {
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }
        formdata.createdBy = userId;
        formdata.createdDate = new Date();
        formdata.approvedFromDate = formdata.fromDate
        formdata.totalSent ="0";
        
        formdata.totalAccepted="0"
        formdata.totalRejected="0"
        formdata.totalPending="0" 
        formdata.modifyBy=0
        
    
        if (new Date(formdata.startDate) > new Date(formdata.endDate)) {
          notify("End date cannot be less than Start date", "warning")
          return;
        }
        apiClient.addEntiyRecord("notifications", formdata).then(res => {
          notify("Notification is created")
          redirect("/notifications");
        }).catch(err => {
          console.log(err);
        });
    
      }
    return (<Create {...props} title="Notification Create" redirect={false} successMessage="Salary Head Updated" undoable={false}>
        <SimpleForm onSubmit={handleSubmit} initialValues={initialValues} redirect="list"   toolbar={<CreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined" >
        {/* <SimpleForm redirect="list"  variant="outlined">*/}
            <DateInput source="date" label="Date" fullWidth={true} formClassName={classes.one_three_input} disabled />
            <ReferenceInput validate={required()} label="For Project" source="projectId" reference="work-role-projects" fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="name" />
            </ReferenceInput>
			
            <TextInput source="notiSubject" label="Notification Subject" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
            <DateInput source="startDate" label="Project Start Date" fullWidth={true} formClassName={classes.one_three_input} validate={required()} />
            <DateInput source="endDate" label="Project End Date" fullWidth={true} formClassName={classes.two_three_input}validate={required()}  />
            <DateInput source="lastRespDate" label="Last Receiving Date" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
            <TextInput source="notiDetails" label="Notification Detail" fullWidth={true} validate={required()} />
            <NumberInput source="totalResource" label="No Of Resources" fullWidth={true} formClassName={classes.one_three_input} validate={required()} />
        {/*    <ReferenceInput validate={required()} label="Team Lead" source="teamLeadId" reference="employees" fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="firstName" /> 
		</ReferenceInput>*/}
			                        <ReferenceInput
              perPage={25} sort={{ field: 'firstName', order: 'ASC' }}
              filterToQuery={searchText => ({ 'firstName~like': searchText })}
              validate={[required()]} label="Team Lead" source="teamLeadId" reference="employees" fullWidth={true} formClassName={classes.two_three_input}>
              {/* <AutocompleteInput optionText="firstName" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} /> */}
              <AutocompleteInput
                    optionText={choice =>
                        `${choice.firstName} ${choice.lastName} (${choice.empCode}) `
                    }
                />
            </ReferenceInput>

            <BooleanInput source="isActive" label="Is Active" fullWidth={true} formClassName={classes.last_three_input} validate={required()} />
            
        </SimpleForm>
    </Create>);
}

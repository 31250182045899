import React from 'react';
import { List, Datagrid, TextField, DateField,ReferenceField, NumberField, BooleanField, EditButton,DeleteButton, ImageField } from 'react-admin';

export const CountryList  = props =>  {
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }
   return( <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Country" >
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
                {propsObj.hasEdit && <EditButton />}
            <TextField source="countryCode" label="Code" />
            <TextField source="countryName" label="Name" />
            <TextField source="regionName" label="Region" />
            <TextField source="dateTimeFormat" label="Date Format" />
            <TextField source="timeFormat" label="Time Format" />
            <TextField source="currencyCode" label="Currency" />
            <TextField source="currencySymbol" label="Currency Symbol" />
            <TextField source="dollarExchangeRate" label="Dollar Exchange Rate" />
            <TextField source="timeZone" label="Time Zone" />
            <TextField source="language" label="Language" />
        </Datagrid>
    </List>
)};
import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField
}
    from 'react-admin';

export const SystemsetupList = props => {
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }
    return (
        <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} >
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
            <TextField source="systemSetupCode" label="Code "/>
            <TextField source="name" label="Name "/>
            <TextField source="value" label="Value "/>
            <TextField source="remarks" label="Remarks "/>
        </Datagrid>
    </List>
)};
import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { CreateToolbar } from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    }
}));

export const WorkRolesProjectsCreate = props => (
    <Create {...props} title="Work Role Project" redirect={false} successMessage="Work Role Project Updated" undoable={false}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<CreateToolbar {...props} />}>
            <TextInput source="name" label="Name" />
            <TextInput source="description" label="Description" />
            <ReferenceInput source="departmentId" reference="departments">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="workRoleId" reference="work-roles">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

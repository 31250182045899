import React from 'react';
import {
    required,     minLength,    maxLength,    minValue,    maxValue,
    number,    regex,    email,    choices,    Create, SimpleForm, TextInput, DateInput,
    ImageInput, ImageField, ReferenceInput, SelectInput,
    BooleanInput, NumberInput
} from 'react-admin';
import { useStyles } from "../../formStyles";

export const UserCreate = props => {
    const classes = useStyles({});
    return (
        <Create {...props} redirect="list">
            <SimpleForm 
            defaultValue={{ middlename: "", pwd: "", roleid: 3, middlename: "", lastname: "",address:"",pincode:"",status:true }}
            variant="outlined">
                <TextInput source="username" validate={[required(), minLength(3), maxLength(20)]} fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="initials" fullWidth={true} formClassName={classes.last_inline_input} />
                <TextInput source="firstname" fullWidth={true} validate={required()}  formClassName={classes.one_three_input} />
                <TextInput source="middlename" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="lastname" fullWidth={true} validate={required()}  formClassName={classes.last_three_input} />
                <TextInput source="email" fullWidth={true} validate={[required(), email()]} formClassName={classes.first_inline_input} />
                <TextInput source="mobile" fullWidth={true} validate={required()} formClassName={classes.last_inline_input} />
                <TextInput source="address" fullWidth={true} />
                <TextInput source="pincode" fullWidth={true} formClassName={classes.first_inline_input} />
                <ReferenceInput label="User Role" source="roleid" reference="roletypes" 
                fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="rolename" />
                </ReferenceInput>
                <TextInput source="pwd" fullWidth={true} validate={[required(), minLength(8), maxLength(15)]} />
                <BooleanInput source="status" />
            </SimpleForm>
        </Create>
    );
}
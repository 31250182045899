import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,FormDataConsumer,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import { AddressFields } from "../../AddressFields";
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";

export const EmployeeEducationCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let startDate = (formdata.startDate) ? moment(formdata.startDate).format() : null;
        let endDate = (formdata.endDate) ? moment(formdata.endDate).format() : null;
        let data = {
            employeeId: parseInt(props.employeeId),
            educationId: formdata.educationId,
            institution: formdata.institution,
            institutionAddress: formdata.institutionAddress,
            institutionCountryId: formdata.institutionCountryId,
            institutionStateId: formdata.institutionStateId,
            institutionCityId: formdata.institutionCityId,
            institutionwebsite: formdata.institutionwebsite,
            subjectId: formdata.subjectId,
            startDate: startDate,
            endDate: endDate,
            remarks: formdata.remarks,
            isActive:true
        }

        apiClient.createEmployeeEducation(data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <ReferenceInput label="Education" validate={required()} source="educationId" reference="educations"
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput validate={required()} formClassName={classes.last_inline_input}
                fullWidth={true} source="institution" 
                label="Institution" />
                <TextInput fullWidth={true} validate={required()}  source="institutionwebsite" formClassName={classes.first_inline_input}
                label="Institution Website" />
                <ReferenceInput label="Subject" validate={required()} formClassName={classes.last_inline_input}
                source="subjectId" reference="subjects"
                    fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <DateInput source="startDate"  validate={required()} fullWidth={true} label="Start Date" formClassName={classes.first_inline_input} />
                <DateInput source="endDate"   validate={required()} fullWidth={true} label="End Date" formClassName={classes.last_inline_input}/>
                <TextInput validate={required()} fullWidth={true} source="institutionAddress" label="Institution Address" />


                <FormDataConsumer>
                    {formDataProps => (
                        <AddressFields {...formDataProps} 
                        countryField="institutionCountryId" stateField="institutionStateId" cityField="institutionCityId" 
                        countryLabel="Institution Country" stateLabel="Institution State" cityLabel="Institution City" 
                        />
                    )}
                </FormDataConsumer>



                {/* <ReferenceInput label="Institution Country"  validate={required()} source="institutionCountryId" reference="countries"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="countryName" />
                </ReferenceInput>

                <ReferenceInput label="Institution State"  validate={required()} source="institutionStateId" reference="states"
                    fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="stateName" />
                </ReferenceInput>
                <ReferenceInput label="Institution City" validate={required()}  source="institutionCityId" reference="cities"
                    fullWidth={true} formClassName={classes.last_three_input}>
                    <SelectInput optionText="cityName" />
                </ReferenceInput> */}

                <TextInput fullWidth={true} multiline  source="remarks"  label="Remarks"/>
            </SimpleForm>
        </Edit>
    );
}

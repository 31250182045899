import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import Segments from './segments/Segments';
import Register from "./account/Register";
import EditProfile from "./account/EditProfile";
import VerifyOTP from "./account/VerifyOTP";
import LoginByOTP from "./account/LoginByOTP";
import StudentSubjectList from "./student/subjects/SubjectList";
import StudentSubjectDetail from "./student/subjects/SubjectDetail";
import TestHeader from "./student/test/TestHeader";
import Quiz from "./student/test/Quiz";
import QuizResult from "./student/test/QuizResult";
import { EmployeeReport } from "./screen/reports/EmployeeReport";
import { EmployeeListReport } from "./screen/reports/EmployeeListReport";
import { EmployeePromoListReport } from "./screen/reports/EmployeePromoListReport";
import { EmployeeWarningListReport } from "./screen/reports/EmployeeWarningListReport";
import { MessageCenterReport } from "./screen/reports/MessCentReport";
import { ImportEmployees } from "./screen/import/import";
import { ImportLeaves } from "./screen/import/importLeaves";
import {ChangePassword} from "./screen/changepassword/ChangePassword";
import {LeaveReport} from "./screen/reports/LeaveReport";
import { Login } from './layout';
import {AttedanceSummaryReport} from "./screen/reports/AttedanceSummaryReport";
export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/segments" render={() => <Segments />} />,
    <Route exact path="/register" render={() => <Register />} noLayout />,
    <Route exact path="/admin" render={() => <Login />} noLayout />,
    <Route exact path="/verifyotp" render={() => <VerifyOTP />} noLayout />,
    <Route exact path="/loginbyotp" render={() => <LoginByOTP />} noLayout />,
    <Route exact path="/studentsubjectlist" render={() => <StudentSubjectList />} />,
    <Route exact path="/studentsubjectdetail/:id" render={() => <StudentSubjectDetail />} />,
    <Route exact path="/subjectsopted" render={() => <StudentSubjectList />} />,
    <Route exact path="/testheader/:id" render={() => <TestHeader />} />,
    <Route exact path="/quiz/:id" render={() => <Quiz />} noLayout />,
    <Route exact path="/quizresult" render={() => <QuizResult />} />,
    <Route exact path="/editprofile" render={() => <EditProfile />} />,
    <Route exact path="/messcentreport" render={(props) => <MessageCenterReport {...props} />} />,
    <Route exact path="/employeelistreport" render={(props) => <EmployeeListReport {...props} />} />,
    <Route exact path="/employeepromolistreport" render={(props) => <EmployeePromoListReport {...props} />} />,
    <Route exact path="/employeewarninglistreport" render={(props) => <EmployeeWarningListReport {...props} />} />,
    <Route exact path="/messcenterreport" render={(props) => <MessageCenterReport {...props} />} />,
    <Route exact path="/importemployee" render={(props) => <ImportEmployees {...props} />} />,
    <Route exact path="/importleaves" render={(props) => <ImportLeaves {...props} />} />,
    <Route exact path="/changepassword" render={(props) => <ChangePassword {...props}/>}  />,
    <Route exact path="/leavereport" render={(props) => <LeaveReport rptTtile="Leave Report" rpt="HRC" {...props} />} />,
    <Route exact path="/attendreport" render={(props) => <AttedanceSummaryReport rptTtile="Attendance Report" rpt="HRC" {...props} />} />,
];

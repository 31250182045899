import React from 'react';
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices, Create, SimpleForm, TextInput, DateInput,
    ImageInput, ImageField, ReferenceInput, SelectInput, FormDataConsumer,
    BooleanInput, NumberInput
} from 'react-admin';
import { useStyles } from "../../formStyles";
import { AddressFields } from "../../AddressFields";

export const EmployeeCreate = props => {
    const classes = useStyles({});
    const savedDisplayAccountType = parseInt(localStorage.getItem("displayEmployeeType")); 
    const initialValues = {
        empRoleTypeId: savedDisplayAccountType,
        isActive: true,
        roEmail:""
        
    }

    return (
        <Create {...props} redirect="list">
            <SimpleForm initialValues={initialValues} variant="outlined">
                <TextInput source="empCode" validate={required()} fullWidth={true} formClassName={classes.first_inline_input} />

                <ReferenceInput label="Initials" validate={required()} source="initialsId" reference="salutations"
                    fullWidth={true} formClassName={classes.last_inline_input} >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput source="firstName" validate={required()} fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="middleName" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="lastName" validate={required()} fullWidth={true} formClassName={classes.last_three_input} />

                <TextInput source="email" validate={required()} fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="alternateEmail" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="personnelEmail" fullWidth={true} formClassName={classes.last_three_input} />

                <TextInput source="mobile" validate={required()} fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="otherMobile" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="homeMobile" fullWidth={true} formClassName={classes.last_three_input} />

                <TextInput source="emergencyContactName" fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="emergencyMobile" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="emergencyOtherMobile" fullWidth={true} formClassName={classes.last_three_input} />

                <SelectInput validate={savedDisplayAccountType != 3 ? required():null} source="gender" fullWidth={true} formClassName={classes.one_three_input}
                    choices={[
                        { id: 'male', name: 'Male' },
                        { id: 'female', name: 'Female' },
                    ]} />
                <TextInput source="skypeId" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="linkedInId" fullWidth={true} formClassName={classes.last_three_input} />


                <TextInput validate={required()} source="presentAddress1" fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput  source="presentAddress2" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput label="Present pincode" source="presentPinCode" fullWidth={true} formClassName={classes.last_three_input} />

                <FormDataConsumer>
                    {formDataProps => (
                        <AddressFields {...formDataProps}
                            countryField="presentCountryId" stateField="presentStateId" cityField="presentCityId"
                            countryLabel="Present Country" stateLabel="Present State" cityLabel="Present City"
                        />
                    )}
                </FormDataConsumer>


                {/* <ReferenceInput validate={required()} label="Present Country" source="presentCountryId" reference="countries"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="countryName" />
                </ReferenceInput>

                <ReferenceInput validate={required()} label="Present State" source="presentStateId" reference="states"
                    fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="stateName" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Present City" source="presentCityId" reference="cities"
                    fullWidth={true} formClassName={classes.last_three_input}>
                    <SelectInput optionText="cityName" />
                </ReferenceInput> */}



                <TextInput validate={required()} source="permanentAddress1" fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="permanentAddress2" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput label="Permanent  pincode" source="permanentPinCode" fullWidth={true} formClassName={classes.last_three_input} />

                <FormDataConsumer>
                    {formDataProps => (
                        <AddressFields {...formDataProps}
                            countryField="permanentCountryId" stateField="permanentStateId" cityField="permanentCityId"
                            countryLabel="Permanent Country" stateLabel="Permanent State" cityLabel="Permanent City"
                        />
                    )}
                </FormDataConsumer>


                {/* <ReferenceInput validate={required()} label="Permanent Country" source="permanentCountryId" reference="countries"
                    fullWidth={true} formClassName={classes.last_three_input}>
                    <SelectInput optionText="countryName" />
                </ReferenceInput>

                <ReferenceInput validate={required()} label="Permanent State" source="permanentStateId" reference="states"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="stateName" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Permanent City" source="permanentCityId" reference="cities"
                    fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="cityName" />
                </ReferenceInput> */}





                <ReferenceInput validate={required()} label="Work Role Type" source="roleTypeId" reference="role-types"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="roleName" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Leave Category" source="leaveCategoryId" reference="leave-categories"
                    fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Shift" source="shiftId" reference="shifts"
                    fullWidth={true} formClassName={classes.last_three_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>


                <DateInput validate={required()} source="dob" fullWidth={true} formClassName={classes.one_three_input} />
                <DateInput validate={required()} source="doj" fullWidth={true} formClassName={classes.two_three_input} />
                <DateInput source="dol" fullWidth={true} formClassName={classes.last_three_input} />

                {/* <ReferenceInput  validate={required()} label="Work Role Type" source="empRoleTypeId" reference="work-roles"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="roleName" />
                </ReferenceInput> */}
                <TextInput source="roEmail"  fullWidth={true} formClassName={classes.first_inline_input} />
                {/* <ReferenceInput validate={required()} label="Department" source="departmentId" reference="departments"
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput> */}
                <ReferenceInput perPage={500} validate={required()} label="Designation" source="designationId" reference="designations"
                    fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput validate={required()} label="Vertical" source="workroleId" reference="work-roles"
                            fullWidth={true} formClassName={classes.first_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Projects" source="workroleprojectId" reference="work-role-projects"
                            fullWidth={true} formClassName={classes.last_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>


                <ReferenceInput validate={required()} label="Recruitment Source" source="recruitmentSourceId" reference="recruit-sources"
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Blood Group" source="bloodgroupId" reference="blood-groups"
                    fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput source="panCardNo" fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="adharCardNo" fullWidth={true} formClassName={classes.last_inline_input} />

                <ReferenceInput validate={required()} label="Work Location" source="workLocationId" reference="work-locations"
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput validate={required()} label="Week off" source="weekOffId" reference="weekoffs"
                    fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput validate={required()} source="userName" fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput validate={required()} source="password" fullWidth={true} formClassName={classes.last_inline_input} />

                <TextInput source="photoUrl" fullWidth={true} />
                <TextInput source="remarks" multiline fullWidth={true} />

                <ReferenceInput label="Status" source="status" reference="statuses" filter={{ statusGroup: 'ER' }}
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="status" />
                </ReferenceInput>
                <ReferenceInput label="Subject" source="subjectId" reference="subjects" 
                    fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <BooleanInput source="isActive" fullWidth={true} formClassName={classes.first_inline_input} />
            </SimpleForm>
        </Create>
    );
}
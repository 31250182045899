import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';
import CameraRearIcon from '@material-ui/icons/CameraRear';
import UserIcon from '@material-ui/icons/People';
import AppsIcon from '@material-ui/icons/Apps';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ImageIcon from '@material-ui/icons/Image';
import EventNoteIcon from '@material-ui/icons/EventNote';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SubMenu from './SubMenu';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { AppState } from '../types';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import MenuUserManagement from './Menu-User-Management';
import MenuAccountManagement from './Menu-Account-Management';
import MenuContentManagement from './Menu-Content-Management';
import MenuSystemManagement from './Menu-System-Management';
import MenuReportManagement from './Menu-Report-Management';
import { MenuContainer } from './MenuContainer';
// import UpcomingEvents from "../upcoming/events";


type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuMasters' | 'menuEvents';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
        menuMasters: false,
        menuUserManagement: false,
        menuAccountManagement: false,
        menuContentManagement: false,
        menuSystemManagement: false,
        menuReportManagement: false,
        menuEvents: false,
    });
    const MyOnMenuClick = () => {
        window.scrollTo(0, 0);
        //onMenuClick();
    }
    const [rights, setRights] = useState([]);
    const [categories, setCategories] = useState<string[]>([]);

    let history = useHistory();
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    const handleLogout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("accounttype");
        localStorage.removeItem("accountid");
        localStorage.removeItem("rolename");
        history.push("/login");
    }
    let userType = localStorage.getItem("type");
    let uid = localStorage.getItem("userId");
    const isAdmin = (userType?.toLocaleLowerCase() == "hr team" || userType?.toLocaleLowerCase() == "system" || userType?.toLocaleLowerCase() == "admin") ? true : false;



    const SetMenuData = () => {
        let menuData = [];
        let menu = localStorage.getItem("menu");
        if (menu) {
            menuData = JSON.parse(menu);
        }
        var outObject = menuData.reduce(function (a:any, e:any) {
            let estKey = (e['ModuleCategory']);
            (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
            return a;
        }, {});
        var keys = Object.keys(outObject);
        setCategories(keys);
        setRights(outObject);
    }

    useEffect(() => {
        SetMenuData();
        console.log("EMPLOYEE LOGGED IN ");
    },[]);
    const url = "/employees/" + uid;
    //const upcomingevents = "/upcomingevents"
    return (
        <div className="left-main-menu">
            {' '}
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            {categories && categories.map((item:any,index:number) =>{
                return(
                    <MenuContainer onMenuClick={MyOnMenuClick} sidebarIsOpen={open} dense={dense} caption={item} items={rights[item]} />
                )
            })}    
            {<MenuItemLink
                to={url}
                primaryText="Profile"
                leftIcon={<ContactSupportIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />}
            {/* {<MenuItemLink
                to={"/importemployee"}
                primaryText="Import Employee"
                leftIcon={<AssessmentIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />}
             {<MenuItemLink
                to={"/importleaves"}
                primaryText="Import Leaves"
                leftIcon={<AssessmentIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense} 
            />}*/}
            <MenuItemLink
                to={`/changepassword`}
                primaryText="Change Password"
                leftIcon={<VpnKeyIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            /> 
            {isXSmall && logout}
        </div>
    );
};

export default Menu;

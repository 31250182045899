import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import Welcome from './Welcome';
import SubjectCount from './SubjectCount';
import TestCount from './TestCount';
import TestResult from "./TestResult";
import LatestCandidates from './LatestCandidates';
import LatestTest from "./LatestTest";
import CandidateCount from './CandidateCount';
import QuestionCount from './QuestionCount';
import * as apiClient from "../apiClient";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CountBlock from "./CountBlock";
import HolidayList from "./HolidayList";
import DashboardLeavesList from "./DashboardLeavesList";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {

    useNotify
} from "react-admin";
export interface IHeader {
    SubjectCount: number;
    TestCount: number;
    QuestionCount: number;
    CandidateCount: number;
}

const useStyles = makeStyles({
    flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center", flexWrap: "wrap" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
});

const Dashboard = () => {
    const [role, setRole] = useState("Employee");
    const [uid, setUid] = useState(0);
    const [header, setHeader] = useState<IHeader>();
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [attType, setAttType] = useState("");
    const [workLocation, setWorkLocation] = useState("");
    const [attOnOff, setattOnOff] = useState(false);
    const [filterCondition, setFilterCondition] = useState("");
    const [db, setDB] = useState([]);
    const [holiday, setHoliday] = useState([]);
    const [leaves, setLeaves] = useState([]);
    const [fetchingAttedanceStatus, setFetchingAttedanceStatus] = useState(true);
    const styles = useStyles({});
    const notify = useNotify();

    const handleAttendance = (event) => {
        if (workLocation === null || workLocation === "") {
            notify("Please Select Work location", "warning")
            return;
        }
        if (attType === "ATT_END" && window.confirm('Do you really want to end your working?')) {
            updateAttedance();
        }
        if (attType === "ATT_START" && window.confirm('Do you really want to Start your working?')) {
            updateAttedance();
        }
    }
    const updateAttedance = () => {
        setFetchingAttedanceStatus(true);
        let userid = localStorage.getItem("userId");
        let formBody = {
            userId: userid,
            attType: attType,
            location: workLocation
        }
        apiClient.updateAttendance(formBody).then(res => {
            notify(res[0].Msg)
            loadAttendance();
        }).catch(err => {
            console.log(err);
        });
    }
    const loadAttendance = () => {
        setFetchingAttedanceStatus(true);
        let userid = localStorage.getItem("userId");
        apiClient.get_attendancestatus(userid).then(res => {
            
            setAttType(res[0].Msg);
            setWorkLocation(res[0].location)
            if (res[0].Msg === 'ATT_START') {
                setattOnOff(false)
            }
            if (res[0].Msg === 'ATT_END') {
                setattOnOff(true)
            }

            setFetchingAttedanceStatus(false);
        }).catch(err => {
            //alert(JSON.stringify(err))
            console.log(err);
        });
    }
    const getDashboard = (user: number) => {
        apiClient.GetDashboard(user).then(res => {
            // console.log(res);
            setDB(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const GetDashboardLeaves = (user: number) => {
        setLeaves([]);
        apiClient.GetDashboardLeaves(user, filterCondition).then(res => {
            //console.log(res);
            setLeaves(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const GetDashboardHoliday = () => {
        apiClient.GetDashboardHoliday().then(res => {
            //console.log(res);
            setHoliday(res);
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        let uid = localStorage.getItem("userId");
        let type = localStorage.getItem("type");
        setRole(type!);
        getDashboard(parseInt(uid!));
        if (type == "HR Team" || type == "System") {
            GetDashboardLeaves(0);
            setUid(0);
        } else {
            GetDashboardLeaves(parseInt(uid!));
            setUid(parseInt(uid!));
        }
        GetDashboardHoliday();
        loadAttendance();

    }, []);


    return (
        <div>
             {!fetchingAttedanceStatus && <div style={{ display: "flex", marginTop: "6px",padding: "6px",fontSize: "12px",    color: "blue",border:"1px solid lightgray" }} >
    
    {attType != "ATT_DONE" && <div>Mark {attType==='ATT_START'?' In ':' Out '}  Attendance  {attType==='ATT_END'?'. You are working from ':"" }</div>}
    {attType != "ATT_DONE" && <div style={{ paddingLeft: 10 }}>
        <select style={{ height: 30 }} onChange={(event) => setWorkLocation(event.target.value)} disabled={attType === "ATT_END" || attType === "ATT_DONE"} >
            <option value="" selected={workLocation === null || workLocation === ""}>Select Work Location</option>
            <option value="WFH" selected={workLocation === "WFH"} >WFH</option>
            <option value="WFO" selected={workLocation === "WFO"}>  WFO</option>
            <option value="WFF" selected={workLocation === "WFF"}>WFF</option>
        </select>
    </div>
    }
    {attType != "ATT_DONE" && <div>
        <FormControlLabel
            value="start"
            control={<Switch color="primary" checked={attOnOff} onChange={(event) => handleAttendance(event)} />}
            label={attType === "ATT_START" ? "Start Your Day" : "End Your Working"}
            labelPlacement="start"
        />
    </div>
    }
    {/* {attType === "ATT_DONE" &&
        <div>
            You have finished your day . Your location WFO and worked 10 hrs 30 mins
    </div>} */}

</div>
}
            <div className={styles.flexCenter}>
                {db && db.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <CountBlock title={item.TypeofEmp} value={item.TotalCount} icon={item.IconUrl} />
                        </div>
                    )
                })}
            </div>
            {/* <div className={styles.flexColumn} style={{ position: "relative" }}>
                <div className="search-container" style={{ textAlign: "left", position: "absolute", left: 0, top: 0, }} >
                    <TextField id="outlined-basic" style={{minWidth:290}}
                        onChange={(e) => { setFilterCondition(e.target.value) }}
                        label="Name/Email/Mobile" variant="outlined" size="small" />
                    <Button variant="contained" color="primary" onClick={() => { GetDashboardLeaves(uid); }}
                        style={{ marginLeft: 10 }}>
                        Search
                    </Button>
                </div>
                <h2 style={{ textAlign: "center" }}>
                    Leave Details</h2>
                {leaves && <DashboardLeavesList data={leaves} />}
                {leaves && leaves.length == 0 && <div style={{ textAlign: "center", margin: 20 }}>No Leaves found</div>}
            </div> */}
            <div className={styles.flexColumn}>
                <h2 style={{ textAlign: "center" }}>Holiday Details</h2>
                {holiday && <HolidayList data={holiday} />}
            </div>
        </div>
    );
};

export default Dashboard;

import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField
}
    from 'react-admin';

export const StateList = props => {
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }
    return (
        <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false}  >
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
                {propsObj.hasEdit && <EditButton />}
            <TextField source="stateName" label="State"/>
            <ReferenceField source="countryId" reference="countries" label="Country"  link={false}>
                <TextField source="countryName" />
            </ReferenceField>
        </Datagrid>
    </List>
)};
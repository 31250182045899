import React, { useState, useEffect } from 'react';
import * as apiClient from "../../apiClient";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink, CSVDownload } from "react-csv";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import moment, { parseZone } from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    required,
    useNotify,
    useRedirect,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    AutocompleteInput,
    Create, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import { MultipleSelect } from "react-select-material-ui";
const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const MessageCenterReport = (props: any) => {


    const classes = useStyles({});
    const [cols, setCols] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [fectched, setFectched] = useState(false);
    const [startdate, setStartDate] = useState('');
    const [enddate, setEndDate] = useState('');
    const [types, setTypes] = useState<any>([]);
    const [type, setType] = useState<any>(0);
    const [statuses, setStatuses] = useState<any>([]);
    const [status, setStatus] = useState<any>(0);
    const [wariningtypes, setWariningTypes] = useState([]);
    const [wariningtype, setWariningtype] = useState<any>(0);
    const [warningStatuses, setWarningStatuses] = useState<any>([]);
    const [warningStatus, setWarningStatus] = useState<any>(0);
    const [teamLeaders, setTeamLeaders] = useState<any>([]);
    const [teamLeadersData, setTeamLeadersData] = useState<any>([]);
    const [selectedteamLeader, setSelectedTeamLeader] = useState<any>({ Id: 0, name: "--ALL--"});
    const [projects, setProjects] = useState<any>([]);
    const [selectedprojects, setSelectedProjects] = useState<any>([]);
    const [projectData, setProjectData] = useState<any>([]);
    const [project, setProject] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        loadTeamLeader();
        loadProjects();

    }, []);
    const handleOnSearch = (string, results) => { } 
    const handleOnHover = (result) => {} 
    const handleOnSelect = (item) => {setSelectedTeamLeader(item) } 
    const handleOnFocus = () => {}
    const formatResult = (item) => {return item;}
    
    const loadTeamLeader = () => {

        apiClient.loadTeamLeader(data).then(res => { 
            setTeamLeaders(res);
            let data = [...teamLeadersData]
            data.push({ id: 0, name: "--ALL--"});
            for (let index = 0; index < res.length; index++) {
                data.push({ id: res[index].id, name: res[index].employeeName });

            }
            setTeamLeadersData(data);

        }).catch(err => {
            console.log(err);
        });
    }
    const loadProjects = () => {

        apiClient.loadProjects(data).then(res => {
            console.log(res);
            let data = [];
            let dataoption = [];

            data = res;
            setProject(res);
            data.map(item => dataoption.push(item["name"]));
            setProjectData(dataoption);


        }).catch(err => {
            console.log(err);
        });
    }
    const generateReport = () => {
        debugger
        if(  startdate==='')
        {
            alert("Please Select From Date ");
            return;
        }
        if(  enddate==='')
        {
            alert("Please Select End Date  ");
            return;
        }
        debugger
        setFectched(false);
        setFectched(true);
        let sDate = moment(startdate).format('YYYY-MM-DD');
        let eDate = moment(enddate).format('YYYY-MM-DD');
        let teamleadid=0;
        let _selectedprojectId='';
        if(selectedteamLeader!=null)
        {
            let _ids=teamLeadersData.filter(x=>x.employeeName===selectedteamLeader.name);
            if(_ids && _ids.length>0)
            {
                teamleadid=_ids[0].id;
            }
            
            
        }
        for (let index = 0; index < selectedprojects.length; index++) {
            if(_selectedprojectId.length>0)
            {
                _selectedprojectId+=","+project.filter(x=>x["name"]===selectedprojects[index])[0].id;
            }
            else 
            {
                _selectedprojectId=project.filter(x=>x["name"]===selectedprojects[index])[0].id;
            }

            
            
        } 
        let data = {
            StartDate: sDate,
            EndDate: eDate,
            Projects: _selectedprojectId,
            TeamLeadId: teamleadid
        };

        apiClient.getnotificationslist(data).then(res => {
            console.log(res);
            setData(res);
            setFectched(true);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                setCols(keys);
            }
            setFectched(false); 
        }).catch(err => {
            console.log(err);
        });
    }
    const handleProjectChange = async (value) => {
        let data = [...projects];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedProjects(data[data.length - 1])
        }
        if (value == null) {
            setSelectedProjects([])
        }
        setProjects([...data])
    };
    useEffect(() => {
        let n = localStorage.getItem('username');
        if (n == null) {
            props.history.push("/login");
        }
         
    }, []);

    return (
        <div className="employee-list-report">
            <div className="filters">
                <div className="filters-input">
                    <div className="start-date">Start Date : <input type="date" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} /></div>
                    <div style={{ marginLeft:"16%" }} className="end-date"> End Date : <input type="date" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} /></div>
                </div>
                <div style={{ display: "flex" }} >
                    <div style={{ width: "40%" }}>
                        <MultipleSelect label="Choose Project" values={projects} options={projectData}
                            helperText="You can search multiple Project" onChange={handleProjectChange}
                            SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All Projects are selected", msgNoOptionsMatchFilter: "No Project name matches the filter", }} />
                    </div>
                    <div style={{ width: "50%",marginLeft:"10%",marginTop:"1%" }}>
                        <ReactSearchAutocomplete
                            placeholder={"Search Team Leader"}
                            showIcon={false}
                            showClear={false}
                            resultStringKeyName={"name"}
                            items={teamLeadersData}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            autoFocus
                            formatResult={formatResult}
                        />
                    </div>

                </div>


                {fectched && data.length > 0 && <div>Total {data.length} employees found</div>}
                {fectched && data.length == 0 && <div>No employees found</div>}
                <div className="filter-action">
                    {data.length > 0 && <CSVLink filename={"Employee_list.csv"} data={data}>Download Report</CSVLink>}
                </div>
            </div>
            <div className="filter-action" >
                <Button variant="contained" 
                
                    onClick={() => {
                        generateReport();
                    }}
                    startIcon={<AssessmentIcon />}
                    color="primary">
                    Generate Report
                </Button>
            </div>
            {isLoading && <CircularProgress />}
            <div className="report-data">
                <div className="w3-responsive">
                    <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">
                        <tr>
                            {cols && cols.map((item, index) => {
                                return (
                                    <th key={item}>{item}</th>
                                )
                            })}
                        </tr>

                        {data && data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {cols && cols.map((col, colIndex) => {
                                        return (
                                            <td key={rowIndex + "-" + colIndex}>
                                                {row[col]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        </div>
    );
};

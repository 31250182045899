import * as http from "./http";

export const APIUrl = "http://52.172.190.126:3006/";
//export const APIUrl = "http://localhost:3006/";

 export const UploadAPIUrl = "http://52.172.190.126:3002/";
// export const UploadAPIUrl = "http://localhost:3006/";

export const innerUploadFile = (field, data) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}uploadFile`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const loadReport = (resource,data) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}
export const getdataWithOrder = (resource,orderField,sortDirection) => {
    let api = `${APIUrl}${resource}?filter[offset]=0&filter[limit]=25000&filter[order]=${orderField}%20${sortDirection}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const ChangePassword = (data) => {
    let api = APIUrl + "employees/update_password";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const getBaseUrl = (url) => {
    if (url.indexOf("?") != -1) {
        url = url.substring(0, url.indexOf("?"));
    }
    return url;
}

export const UploadFile = async (field, data) => {
    if (data && data.rawFile) {
        let response = await innerUploadFile(field, data);
        if (response.files && response.files.length > 0) {
            return getBaseUrl(response.files[0].url);
        } else {
            return data;
        }
    }
    return data;
}


export const innerUploadImportFile = (field, data, type, process) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}employeeimport?type=${type}&process=${process}`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const UploadImportFile = async (field, data, type, process) => {
    if (data && data.rawFile) {
        let response = await innerUploadImportFile(field, data, type , process);
        return response;
    }
    return data;
}

export const innerUploadLeaveImportFile = (field, data, process) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}employeeleaves?process=${process}`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const UploadLeaveImportFile = async (field, data, process) => {
    if (data && data.rawFile) {
        let response = await innerUploadLeaveImportFile(field, data, process);
        return response;
    }
    return data;
}


export const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

        // swap elements array[i] and array[j]
        // we use "destructuring assignment" syntax to achieve that
        // you'll find more details about that syntax in later chapters
        // same can be written as:
        // let t = array[i]; array[i] = array[j]; array[j] = t
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const GetDashboard = (userid) => {
    let api = `${APIUrl}employees/dashboard/${userid}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const get_attendancestatus = (userid) => {
    let api = APIUrl + `employees/checkemploginstatus/`+userid;
    return http.get(api).then(response => {
        return response;
    });
}
export const GetDashboardLeaves = (userid, FilterText) => {
    let api = `${APIUrl}employees/dashboardleaves/${userid}?FilterText=${FilterText}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const GetDashboardHoliday = () => {
    let api = `${APIUrl}employees/dashboardholiday`;
    return http.get(api).then(response => {
        return response;
    });
}


export const GetDashboardHeader = (userid, roleid) => {
    let api = `${APIUrl}getdashboardheader/${userid}/${roleid}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const createEmployeeEducation = (data) => {
    let api = APIUrl + "employee-educations";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getEmployees = () => {
    let api = `${APIUrl}employees`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getDepartments = () => {
    let api = `${APIUrl}departments`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getResource = (resource) => {
    let api = `${APIUrl}${resource}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getRoleTypes = () => {
    let api = `${APIUrl}role-types`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getStatuses = () => {
    let api = `${APIUrl}statuses`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getWarningStatuses = () => {
    let api = `${APIUrl}statuses?filter[offset]=0&filter[limit]=25&filter[order]=id%20DESC&filter[where][statusGroup]=ET`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getWarningTypes = () => {
    let api = `${APIUrl}statuses?filter[offset]=0&filter[limit]=25&filter[order]=id%20DESC&filter[where][statusGroup]=ES`;
    return http.get(api).then(response => {
        return response;
    });
}


export const getEmployeeListReport = (data) => {
    let api = `${APIUrl}employees/employeelistreport`;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getPromoEmployeeListReport = (data) => {
    let api = `${APIUrl}employees/employeepromolistreport`;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getWarningEmployeeListReport = (data) => {
    let api = `${APIUrl}employees/employeewarninglistreport`;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getnotificationslist = (data) => {
    let api = `${APIUrl}notifications/getnotificationslist`;
    return http.post(api, data).then(response => {
        return response;
    });
}
export const loadTeamLeader = (data) => {
    let api = `${APIUrl}/employees/getteamlead`;
    return http.get(api).then(response => {
        return response;
    });
    
}
export const loadProjects = (data) => {
    let api = `${APIUrl}/notifications/getprojects`;
    return http.get(api).then(response => {
        return response;
    });
    
}

export const getMessageCenterReport = (data) => {
    let api = `${APIUrl}employees/messagecenterreport`;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const createEmployeeEmployment = (data) => {
    let api = APIUrl + "employee-prev-infos";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const createEmployeeLeaves = (data) => {
    let api = APIUrl + "employee-leaves";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const deleteRecord = (path, id) => {
    let api = APIUrl + path + "/" + id;
    return http.del(api).then(response => {
        return response;
    });
}
export const createEmployeeSalary = (data) => {
    let api = APIUrl + "employee-salaries";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const createEmployeeWarning = (data) => {
    let api = APIUrl + "employee-warnings";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateRolerights = (data) => {
    let api = APIUrl + "role-types/rights";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateRoleType = (id, data) => {
    let api = APIUrl + `role-types/${id}`;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const getmodules = (user_id) => {
    let api = APIUrl + "employees/getmodules/" + user_id;
    return http.get(api).then(response => {
        return response;
    });
}
export const createEmployeeReporting = (data) => {
    let api = APIUrl + "employee-reportings";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const createEmployeePromotion = (data) => {
    let api = APIUrl + "employee-promotions";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const createEmployeeSubjectExpert = (data) => {
    let api = APIUrl + "employee-subject-experts";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const createEmployeeDepartment = (data) => {
    let api = APIUrl + "employee-departments";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const isUserAllowedTest = (userid, testid) => {
    let api = `${APIUrl}isuserallowedtest/${userid}/${testid}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const GetAllSubjectTestsByUserID = (userid, subjectid) => {
    let api = `${APIUrl}getallsubjecttestsbyuserid/${userid}/${subjectid}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const CheckValidTestForUser = (userid, testid) => {
    let api = `${APIUrl}checkvalidtestforuser/${userid}/${testid}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const GetAllSubjects = () => {
    let api = APIUrl + "subjects?filter[where][isactive]=1";
    return http.get(api).then(response => {
        return response;
    });
}

export const GetRestResponse = (id) => {
    let api = `${APIUrl}testresponses/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const GetSubject = (id) => {
    let api = `${APIUrl}subjects/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}
// export const GetAllSubjects = () => {
//     let api = `${APIUrl}subjects?filter[offset]=0&filter[limit]=1000&filter[order]=name%20ASC`;
//     return http.get(api).then(response => {
//         return response;
//     });
// }
export const getAllTestForSubject = (subjectid) => {
    let api = `${APIUrl}tests?filter[where][subjectid]=${subjectid}&filter[where][isactive]=1`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getTestDetailsById = (testid) => {
    let api = `${APIUrl}testdetails/${testid}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const registerUser = (user) => {
    let api = APIUrl + "registerUser";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const searchResource = (data) => {
    let api = APIUrl + "notifications/searchresource";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const addResource = (data) => {
    let api = APIUrl + "notifications/addresource";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const getNotificationdetail = (data) => {
    let api = APIUrl + "notifications/getdetail";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getReport = (url, data) => {
    let api = url;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const loginUser = (user) => {
    let api = APIUrl + "login";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const loginWithOTP = (mobile) => {
    let api = APIUrl + `getOTP/${mobile}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const verifyOTP = (phone, otp) => {
    let api = APIUrl + `verifyOTP/${phone}/${otp}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getOTP = (phone) => {
    let api = APIUrl + `getOTP/${phone}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const addTestResponse = (userid, subjectid, testid) => {
    let api = APIUrl + "addtestresponse";
    let data = {
        userid: userid,
        subjectid: subjectid,
        testid: testid
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const addTestResponseDetail = (testresponseid, testquestionid, testquestionoptionid, userid, subject) => {
    let api = APIUrl + "addtestresponsedetail";
    let data = {
        testresponseid: testresponseid,
        testquestionid: testquestionid,
        testquestionoptionid: testquestionoptionid,
        userid: userid,
        subject: subject
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getfunctionbyroletype = (roleid) => {
    let api = `${APIUrl}employees/getfunctionbyroletype/${roleid}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const addEntiyRecord = (resource, data) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateleave = (data) => {
    let api = APIUrl + "/employee-apply-leaves/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const updateAttendance = (data) => {
    let api = APIUrl + "/employees/attendanceentry";
    return http.post(api, data).then(response => {
        return response;
    });
}
import React from 'react';
import Button from '@material-ui/core/Button';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";


const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
    },
    subjectsContainer: { display: 'flex' },
    subject: { margin: 20, marginBottom: 20, border: "1px solid #cccccc", },
    subjectImage: {
        maxWidth: "100%",
        width: 100,
        height: 100,
        padding: 10,
    },
    subjectName: { fontWeight: "bold", textAlign: "center" },
    instructions: { padding: 10, },
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
});

const StudentTest = () => {
    const classes = useStyles({});
    let { id } = useParams();
    return (
        <div className={classes.root}>
            <h1>Instructions</h1>
            <div className={classes.instructions}>
                <ul>
                    <li>Do not unnecessarily refresh the browser window while giving the test.</li>
                    <li>Do not forget to click on 'End Test' after you have submitted the test.</li>
                    <li>If you want to highlight some text in a paragraph, just select it. It will remain highlighted as long as you do not navigate from that question.</li>
                </ul>
            </div>
            <div className={classes.subjectsContainer}>
                <div className={classes.subject}>
                <Button variant="contained" color="primary" href={"#quiz/" + id}>
                            Start Test
                </Button>
                </div>
            </div>
        </div>
    );
};

export default StudentTest;

import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    }
}));

export const WorkRolesProjectsEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props}>
            <SimpleForm redirect="list"
                variant="outlined">
                <TextInput source="name" label="Name" />
                <TextInput source="description" label="Description" />
                <ReferenceInput source="departmentId" reference="departments">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="workRoleId" reference="work-roles">
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
}
import React, { useState, useEffect, useCallback } from 'react';
import {
    required, minLength, maxLength, minValue, maxValue, useRefresh,
    number, regex, email, choices,
    TabbedForm, FormTab, ReferenceInput,
    Edit, SimpleForm, TextInput, RichTextField,
    SelectInput, EditButton, NumberField, DateField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField, FormDataConsumer,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField,
} from 'react-admin';
import { AddressFields } from "../../AddressFields";
import Button from '@material-ui/core/Button';
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { Link } from '@material-ui/core';
import { CreateToolbar } from "../../CreateToolbar";
import { Drawer } from '@material-ui/core';
import { Route, useRouteMatch, useLocation } from 'react-router-dom';

import { EmployeeEducationCreate } from "./EmployeeEducationCreate";
import { EmployeeEmploymentCreate } from "./EmployeeEmploymentCreate";
import { EmployeeLeaveCreate } from "./EmployeeLeaveCreate";
import { EmployeeSubjectExpertCreate } from "./EmployeeSubjectExpertCreate";
import { EmployeeSalaryCreate } from "./EmployeeSalaryCreate";
import { EmployeeWarningCreate } from "./EmployeeWarningCreate";
import { EmployeeReportingCreate } from "./EmployeeReportingCreate";
import { EmployeePromotionCreate } from "./EmployeePromotionCreate";
import VSDeleteButton from "../../VSDeleteButton";
import { wrap } from 'module';
import { EmployeeDepartmentCreate } from './EmployeeDepartmentCreate';
const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 750,
    },
}));
export const EmployeeEdit = props => {


    const refresh = useRefresh();
    const classes = useStyles({});
    const myclasses = myStyles({});
    const [role, setRole] = useState("Employee");
    const [uid, setUid] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isEmpEducation, setIsEmpEducation] = useState(false);
    const [isEmpEmployment, setIsEmpEmployment] = useState(false);
    const [isEmpLeave, setIsEmpLeave] = useState(false);
    const [isEmpSubjectExpert, setIsEmpSubjectExpert] = useState(false);
    const [isEmpDepartment, setIsEmpDepartment] = useState(false);
    const [isEmpSalary, setIsEmpSalary] = useState(false);
    const [isEmpWarning, setIsEmpWarning] = useState(false);
    const [isEmpReporting, setIsEmpReporting] = useState(false);
    const [isEmpPromotion, setIsEmpPromotion] = useState(false);

    const match = useRouteMatch();
    const location = useLocation();


    let canseesalarytab = false;
    let issalarytab = localStorage.getItem("issalarytab");
    //console.log(issalarytab, "====issalarytab======");
    if (issalarytab == "true") {
       // console.log(canseesalarytab, "====condition======");
        canseesalarytab = true;
    }

    const url = match ? match.url : location.pathname;
    //console.log(match);
    //console.log(location);

    const onCloseForm = () => {
        setIsEmpEducation(false);
        setIsEmpEmployment(false);
        setIsEmpLeave(false);
        setIsEmpSubjectExpert(false);
        setIsEmpDepartment(false);
        setIsEmpSalary(false);
        setIsEmpWarning(false);
        setIsEmpReporting(false);
        setIsEmpPromotion(false);
        refresh();
    }
    const onCancel = () => {
        setIsEmpEducation(false);
        setIsEmpEmployment(false);
        setIsEmpLeave(false);
        setIsEmpSubjectExpert(false);
        setIsEmpDepartment(false);
        setIsEmpSalary(false);
        setIsEmpWarning(false);
        setIsEmpReporting(false);
        setIsEmpPromotion(false);
    }


    useEffect(() => {
        let uid = localStorage.getItem("userId");
        let type = localStorage.getItem("type");
        setRole(type);
        console.log(type);
        setUid(parseInt(uid));
        if (type == "System")
            setIsAdmin(true);
    }, []);

    return (
        <React.Fragment>
            <Edit {...props} title="Employee" undoable={false}>
                <TabbedForm variant="outlined" toolbar={(role == "Employee") ? null : <CreateToolbar {...props} mode={"Edit"} />}>
                    <FormTab value="DETAILS" label="Details">

                        <TextInput source="empCode" validate={required()} fullWidth={true} formClassName={classes.first_inline_input} />
                        <ReferenceInput label="Initials" validate={required()} formClassName={classes.last_inline_input}
                            source="initialsId"
                            reference="salutations"
                            fullWidth={true} >
                            <SelectInput optionText="name" />
                        </ReferenceInput>

                        <TextInput source="firstName" validate={required()} fullWidth={true} formClassName={classes.one_three_input} />
                        <TextInput source="middleName" fullWidth={true} formClassName={classes.two_three_input} />
                        <TextInput source="lastName" validate={required()} fullWidth={true} formClassName={classes.last_three_input} />

                        <TextInput source="email" validate={required()} fullWidth={true} formClassName={classes.one_three_input} />
                        <TextInput source="alternateEmail" fullWidth={true} formClassName={classes.two_three_input} />
                        <TextInput source="personnelEmail" fullWidth={true} formClassName={classes.last_three_input} />

                        <TextInput source="mobile" validate={required()} fullWidth={true} formClassName={classes.one_three_input} />
                        <TextInput source="otherMobile" fullWidth={true} formClassName={classes.two_three_input} />
                        <TextInput source="homeMobile" fullWidth={true} formClassName={classes.last_three_input} />

                        <TextInput source="emergencyContactName" fullWidth={true} formClassName={classes.one_three_input} />
                        <TextInput source="emergencyMobile" fullWidth={true} formClassName={classes.two_three_input} />
                        <TextInput source="emergencyOtherMobile" fullWidth={true} formClassName={classes.last_three_input} />

                        <SelectInput validate={required()} source="gender" fullWidth={true} formClassName={classes.one_three_input}
                            choices={[
                                { id: 'male', name: 'Male' },
                                { id: 'female', name: 'Female' },
                            ]} />
                        <TextInput source="skypeId" fullWidth={true} formClassName={classes.two_three_input} />
                        <TextInput source="linkedInId" fullWidth={true} formClassName={classes.last_three_input} />



                        <ReferenceInput validate={required()} label="User Role" source="roleTypeId" reference="role-types"
                            fullWidth={true} formClassName={classes.one_three_input}>
                            <SelectInput optionText="roleName" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Leave Category" source="leaveCategoryId" reference="leave-categories"
                            fullWidth={true} formClassName={classes.two_three_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Shift" source="shiftId" reference="shifts"
                            fullWidth={true} formClassName={classes.last_three_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>


                        <DateInput validate={required()} source="dob" fullWidth={true} formClassName={classes.one_three_input} />
                        <DateInput validate={required()} source="doj" fullWidth={true} formClassName={classes.two_three_input} />
                        <DateInput source="dol" fullWidth={true} formClassName={classes.last_three_input} />


                        {/* <ReferenceInput  validate={required()} label="Employee Role Type" source="empRoleTypeId" reference="role-types"
                            fullWidth={true} formClassName={classes.one_three_input}>
                            <SelectInput optionText="roleName" />
                        </ReferenceInput> */}
                        <TextInput source="roEmail"  fullWidth={true} formClassName={classes.first_inline_input} />
                        {/* 
                        <ReferenceInput label="Department" source="departmentId" reference="departments" disabled 
                            fullWidth={true} formClassName={classes.first_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput> */}
                        <ReferenceInput perPage={500} validate={required()} label="Designation" source="designationId" reference="designations"
                            fullWidth={true} formClassName={classes.last_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>

                        <ReferenceInput validate={required()} label="Vertical" source="workroleId" reference="work-roles"
                            fullWidth={true} formClassName={classes.first_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Projects" source="workroleprojectId" reference="work-role-projects"
                            fullWidth={true} formClassName={classes.last_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>

                        <ReferenceInput validate={required()} label="Recruitment Source" source="recruitmentSourceId" reference="recruit-sources"
                            fullWidth={true} formClassName={classes.first_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Blood Group" source="bloodgroupId" reference="blood-groups"
                            fullWidth={true} formClassName={classes.last_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>


                        <TextInput source="panCardNo" fullWidth={true} formClassName={classes.first_inline_input} />
                        <TextInput source="adharCardNo" fullWidth={true} formClassName={classes.last_inline_input} />

                        <ReferenceInput validate={required()} label="Work Location" source="workLocationId" reference="work-locations"
                            fullWidth={true} formClassName={classes.first_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()} label="Week off" source="weekOffId" reference="weekoffs"
                            fullWidth={true} formClassName={classes.last_inline_input}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>

                        <TextInput disabled validate={required()} source="userName" fullWidth={true} formClassName={classes.first_inline_input} />
                        <TextInput validate={required()} source="password" fullWidth={true} formClassName={classes.last_inline_input} />

                        <TextInput source="remarks" fullWidth={true} multiline />

                        <ReferenceInput label="Status" source="status" reference="statuses" filter={{ statusGroup: 'ER' }}
                            fullWidth={true} formClassName={classes.first_inline_input}>
                            <SelectInput optionText="status" />
                        </ReferenceInput>
                        <BooleanInput source="isActive" fullWidth={true} formClassName={classes.last_inline_input} />
                        <DateInput disabled source="entryDate" fullWidth={true} formClassName={classes.first_inline_input} />
                        <SelectInput validate={required()} source="empRoleTypeId" fullWidth={true}
                         formClassName={classes.last_inline_input}
                            choices={[
                                { id: 1, name: 'On-Roll' },
                                { id: 3, name: 'Freelancer' },
                                { id: 4, name: 'Contractual' },
                            ]} />
                         <ReferenceInput label="Subject" source="subjectId" reference="subjects" 
                                fullWidth={true} formClassName={classes.first_inline_input}>
                            <SelectInput optionText="name" />
                            </ReferenceInput>   

                    </FormTab>
                    <FormTab value="Other" label="Other">

                        {isAdmin && <ImageInput source="photoUrl" formClassName={classes.first_inline_input}
                            label="Employee photo" accept="image/*" className="logourl">
                            <ImageField source="photoUrl" title="Icon" />
                        </ImageInput>}
                        <ImageField source="photoUrl" label="Employee photo" formClassName={classes.last_inline_input} />



                        {isAdmin && <ImageInput source="adharcardFrontUrl" formClassName={classes.one_4_input}
                            label="Aadhar photo (Front)" accept="image/*" className="logourl">
                            <ImageField source="adharcardFrontUrl" title="Icon" />
                        </ImageInput>}
                        <ImageField source="adharcardFrontUrl" label="Aadhar photo (Front)" formClassName={classes.two_4_input} />

                        {isAdmin && <ImageInput source="adharcardBackUrl" formClassName={classes.three_4_input}
                            label="Aadhar photo (Back)" accept="image/*" className="logourl">
                            <ImageField source="adharcardBackUrl" title="Icon" />
                        </ImageInput>}
                        <ImageField source="adharcardBackUrl" label="Aadhar photo (Back)" formClassName={classes.last_4_input} />



                        {isAdmin && <ImageInput source="pancardFrontUrl" formClassName={classes.one_4_input}
                            label="PanCard photo (Back)" accept="image/*" className="logourl">
                            <ImageField source="pancardFrontUrl" title="Icon" />
                        </ImageInput>}
                        <ImageField source="phopancardFrontUrltoUrl" label="PanCard photo (Back)" formClassName={classes.two_4_input} />

                        {isAdmin && <ImageInput source="pancardBackUrl" formClassName={classes.three_4_input}
                            label="Pancard photo (Back)" accept="image/*" className="logourl">
                            <ImageField source="pancardBackUrl" title="Icon" />
                        </ImageInput>}
                        <ImageField source="pancardBackUrl" label="Pancard photo (Back)" formClassName={classes.last_4_input} />


                        <TextInput validate={required()} source="presentAddress1" fullWidth={true} formClassName={classes.one_three_input} />
                        <TextInput source="presentAddress2" fullWidth={true} formClassName={classes.two_three_input} />
                        <TextInput label="Present pincode" source="presentPinCode" fullWidth={true} formClassName={classes.last_three_input} />

                        <FormDataConsumer>
                            {formDataProps => (
                                <AddressFields {...formDataProps}
                                    countryField="presentCountryId" stateField="presentStateId" cityField="presentCityId"
                                    countryLabel="Present Country" stateLabel="Present State" cityLabel="Present City"
                                />
                            )}
                        </FormDataConsumer>



                        {/* <ReferenceInput  validate={required()} label="Present Country" source="presentCountryId" reference="countries"
                            fullWidth={true} formClassName={classes.last_three_input}>
                            <SelectInput optionText="countryName" />
                        </ReferenceInput>

                        <ReferenceInput  validate={required()} label="Present State" source="presentStateId" reference="states"
                            fullWidth={true} formClassName={classes.one_three_input}>
                            <SelectInput optionText="stateName" />
                        </ReferenceInput>
                        <ReferenceInput validate={required()}  label="Present City" source="presentCityId" reference="cities"
                            fullWidth={true} formClassName={classes.two_three_input}>
                            <SelectInput optionText="cityName" />
                        </ReferenceInput> */}


                        <TextInput validate={required()} source="permanentAddress1" fullWidth={true} formClassName={classes.one_three_input} />
                        <TextInput source="permanentAddress2" fullWidth={true} formClassName={classes.two_three_input} />
                        <TextInput label="Permanent Pincode" source="permanentPinCode" fullWidth={true} formClassName={classes.last_three_input} />

                        <FormDataConsumer>
                            {formDataProps => (
                                <AddressFields {...formDataProps}
                                    countryField="permanentCountryId" stateField="permanentStateId" cityField="permanentCityId"
                                    countryLabel="Permanent Country" stateLabel="Permanent State" cityLabel="Permanent City"
                                />
                            )}
                        </FormDataConsumer>

                        {/* <ReferenceInput label="Permanent Country" source="permanentCountryId" reference="countries"
                            fullWidth={true} formClassName={classes.last_three_input}>
                            <SelectInput optionText="countryName" />
                        </ReferenceInput>

                        <ReferenceInput  validate={required()} label="Permanent State" source="permanentStateId" reference="states"
                            fullWidth={true} formClassName={classes.one_three_input}>
                            <SelectInput optionText="stateName" />
                        </ReferenceInput>
                        <ReferenceInput  validate={required()} label="Permanent City" source="permanentCityId" reference="cities"
                            fullWidth={true} formClassName={classes.two_three_input}>
                            <SelectInput optionText="cityName" />
                        </ReferenceInput> */}

                    </FormTab>
                    <FormTab value="EDUCATION" label="Education">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpEducation(true); }}
                            color="secondary">
                            Add Education
                        </Button>}
                        <ReferenceManyField perPage={100} reference="employee-educations" target="employeeId" addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <ReferenceField source="educationId" reference="educations" link={false}><TextField source="name" /></ReferenceField>
                                <TextField source="institution" />
                                <ReferenceField source="subjectId" reference="subjects" link={false}><TextField source="name" /></ReferenceField>
                                <DateField source="startDate" />
                                <DateField source="endDate" />
                                <TextField source="remarks" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                    <FormTab value="EMPLOYMENT" label="Prev. Emp.">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpEmployment(true); }}
                            color="secondary">
                            Add Employment
                        </Button>}
                        <ReferenceManyField perPage={100} reference="employee-prev-infos" target="employeeId" addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <TextField source="organization" />
                                <TextField source="organizationAddress" />
                                <TextField source="website" />
                                <TextField source="reportingto" />
                                <ReferenceField source="subjectId" reference="subjects" link={false}><TextField source="name" /></ReferenceField>
                                <ReferenceField source="empRoleTypeId" reference="work-roles" link={false}><TextField source="name" /></ReferenceField>
                                <ReferenceField source="departmentId" reference="departments" link={false}><TextField source="name" /></ReferenceField>
                                <ReferenceField source="designationId" reference="designations" link={false}><TextField source="name" /></ReferenceField>
                                <DateField source="doj" />
                                <DateField source="dol" />
                                {/* <TextField source="remarks" /> */}
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                    <FormTab value="LEAVE" label="Leave">
                        {/* {isAdmin && <Button variant="contained" onClick={() => { setIsEmpLeave(true); }}
                            color="secondary">
                            Add Leave
                            </Button>} */}
                        <ReferenceManyField perPage={100} reference="employee-leavess" target="employeeId" addLabel={false} fullWidth={true} >
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                {/* <VSDeleteButton {...props}/> */}
                                {/* <ReferenceField source="leaveTypeId" reference="leave-types" link={false}><TextField source="name" /></ReferenceField> */}
                                <TextField source="leaveYear" />
                                <TextField source="leaveMonth" />
                                <NumberField source="totAvailLeaveDetails" label="Total Leaves" />
                                <NumberField source="sl" label="SL" />
                                <NumberField source="el" label="EL" />
                                <NumberField source="co" label="COL" />
                                <NumberField source="cl" label="CL" />
                                <NumberField source="ol" label="OL" />
                                <NumberField source="slcl" label="SL Closing" />
                                <NumberField source="elcl" label="EL Closing" />
                                <NumberField source="cocl" label="COL Closing" />
                                <NumberField source="clcl" label="CL Closing" />
                                <NumberField source="olcl" label="OL Closing" />
                                <NumberField source="leaveBal" label="Balance" />
                                <TextField source="remarks" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                    <FormTab value="SUBJECT" label="Subject">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpSubjectExpert(true); }}
                            color="secondary">
                            Add Subject Expert
                        </Button>}
                        <ReferenceManyField perPage={100} reference="employee-subject-experts" target="employeeId" addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <DateField source="date" />
                                <ReferenceField source="subjectId" reference="subjects" link={false}><TextField source="name" /></ReferenceField>
                                <TextField source="remarks" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                    <FormTab value="DEPARTMENT" label="Deparment">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpDepartment(true); }}
                            color="secondary">
                            Add Department
                        </Button>}
                        <ReferenceManyField perPage={100} reference="employee-departments" target="employeeId" addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <DateField source="fromDate" />
                                <DateField source="toDate" />
                                <ReferenceField source="departmentId" reference="departments" link={false}>
                                    <TextField source="name" /></ReferenceField>
                                <TextField source="remarks" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                    {canseesalarytab && <FormTab value="SALARY" label="Salary">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpSalary(true); }}
                            color="secondary">
                            Add Salary
                        </Button>}
                        <ReferenceManyField perPage={200} reference="employee-salaries" sort={{ field: 'id', order: 'ASC' }}
                            target="employeeId"
                            addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <ReferenceField source="salaryHeadId" reference="salary-heads" link={false}><TextField source="name" /></ReferenceField>
                                <TextField source="calFormulae" />
                                <NumberField source="grossSalary" />
                                <NumberField source="monthlySalary" />
                                <DateField source="effectiveFrom" />
                                <DateField source="effectiveTo" />
                                <TextField source="remarks" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>}
                    <FormTab value="PROMOTION" label="Promotion/Role Changes">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpPromotion(true); }}
                            color="secondary">
                            Add Promotion
                        </Button>}
                        <ReferenceManyField perPage={100} reference="employee-promotions" target="employeeId" addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <ReferenceField source="fromRoleId" label="From Vertical" reference="work-roles" link={false}><TextField source="name" /></ReferenceField>
                                <ReferenceField source="toRoleId" label="To Vertical" reference="work-roles" link={false}><TextField source="name" /></ReferenceField>
                                <ReferenceField source="fromDepartMentId" label="From Dept." reference="departments" link={false}><TextField source="name" /></ReferenceField>
                                <ReferenceField source="toDepartMentId" label="To Dept." reference="departments" link={false}><TextField source="name" /></ReferenceField>
                                <ReferenceField source="fromDesignationId" label="From Desig." reference="designations" link={false}><TextField source="name" /></ReferenceField>
                                <ReferenceField source="toDesignationId" label="To Desig." reference="designations" link={false}><TextField source="name" /></ReferenceField>
                                <DateField source="fromDate" label="From Date" />
                                <DateField source="toDate" label="To Date" />
                                <ReferenceField source="issuedBy" label="Issued By" reference="departments" link={false}><TextField source="name" /></ReferenceField>
                                <TextField source="approvedBy" label="Approved By" />
                                <TextField source="remarks" label="Remarks" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                    <FormTab value="WARNING" label="Warning">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpWarning(true); }}
                            color="secondary">
                            Add Warning
                        </Button>}
                        <ReferenceManyField perPage={100} reference="employee-warnings" target="employeeId" addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <DateField source="date" />
                                <ReferenceField source="typeId" label="Type" reference="statuses" link={false}><TextField source="status" /></ReferenceField>
                                <DateField source="startDate" label="Start Date" />
                                <DateField source="endDate" label="End Date" />
                                <ReferenceField source="statusId" label="Status" reference="statuses" link={false}><TextField source="status" /></ReferenceField>
                                <ReferenceField source="issuedBy" label="Issued By" reference="departments" link={false}><TextField source="name" /></ReferenceField>
                                <TextField source="approvedBy" label="Approved By" />
                                <TextField source="remarks" label="Remarks" />
                                <BooleanField source="isActive" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                    <FormTab value="REPORTING" label="Reporting">
                        {isAdmin && <Button variant="contained" onClick={() => { setIsEmpReporting(true); }}
                            color="secondary">
                            Add Reporting
                        </Button>}
                        <ReferenceManyField perPage={100} reference="employee-reportings" target="employeeId" addLabel={false} fullWidth={true}>
                            <Datagrid rowClick={false} style={{ tableLayout: 'fixed' }}>
                                <VSDeleteButton {...props} />
                                <DateField source="date" />
                                <ReferenceField source="firstReportingEmpId" reference="employees" link={false}><TextField source="firstName" /></ReferenceField>
                                <ReferenceField source="secondReportingEmpId" reference="employees" link={false}><TextField source="firstName" /></ReferenceField>
                                <TextField source="remarks" />
                                <BooleanField source="isActive" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                </TabbedForm>
            </Edit>
            <Drawer
                anchor="right"
                onClose={onCancel}
                classes={{
                    paper: myclasses.drawerPaper,
                }}
                open={isEmpEducation || isEmpEmployment || isEmpSalary || isEmpPromotion ||
                    isEmpLeave || isEmpSubjectExpert || isEmpWarning || isEmpReporting || isEmpDepartment} >
                {isEmpEducation &&
                    <EmployeeEducationCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpEmployment &&
                    <EmployeeEmploymentCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpLeave &&
                    <EmployeeLeaveCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpSubjectExpert &&
                    <EmployeeSubjectExpertCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpSalary &&
                    <EmployeeSalaryCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpDepartment &&
                    <EmployeeDepartmentCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpPromotion &&
                    <EmployeePromotionCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpWarning &&
                    <EmployeeWarningCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
                {isEmpReporting &&
                    <EmployeeReportingCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        employeeId={props.id}
                        {...props} />}
            </Drawer>
        </React.Fragment>
    );
}
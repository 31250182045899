import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../apiClient";
import { CreateToolbar } from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";

export const EmployeeEmploymentCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        isActive: true
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let dol = (formdata.dol) ? moment(formdata.dol).format() : null;
        let doj = (formdata.doj) ? moment(formdata.doj).format() : null;

        let data = {
            employeeId: parseInt(props.employeeId),
            organization: formdata.organization,
            organizationAddress: formdata.organizationAddress,
            organizationCountryId: formdata.organizationCountryId,
            organizationStateId: formdata.organizationStateId,
            organizationCityId: formdata.organizationCityId,
            website: formdata.website,
            reportingto: formdata.reportingto,
            subjectId: formdata.subjectId,
            empRoleTypeId: formdata.empRoleTypeId,
            departmentId: formdata.departmentId,
            designationId: formdata.designationId,
            dol: dol,
            doj: doj,
            remarks: formdata.remarks,
            isActive: true
        }

        apiClient.createEmployeeEmployment(data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar label="Save" onClose={props.onCancel} />}
                variant="outlined">
                <TextInput fullWidth={true} source="organization" formClassName={classes.first_inline_input}
                    label="Organization" />
                <TextInput validate={required()} formClassName={classes.last_inline_input}
                    fullWidth={true} source="reportingto" label="Reporting To" />
                <TextInput fullWidth={true} source="website" formClassName={classes.first_inline_input}
                    label="Website" />
                <ReferenceInput label="Subject" validate={required()} formClassName={classes.last_inline_input}
                    source="subjectId" reference="subjects" fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Work Role" validate={required()} formClassName={classes.one_three_input}
                    source="empRoleTypeId" reference="work-roles" fullWidth={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput label="Department" source="departmentId" reference="departments"
                    fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput label="Designation" source="designationId" reference="designations"
                    fullWidth={true} formClassName={classes.last_three_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>


                <DateInput source="doj" fullWidth={true} label="Joining Date" formClassName={classes.first_inline_input} />
                <DateInput source="dol" fullWidth={true} label="Leaving Date" formClassName={classes.last_inline_input} />


                <TextInput validate={required()} fullWidth={true} source="organizationAddress" label="Organization Address" />

                <ReferenceInput label="Organization Country" source="organizationCountryId" reference="countries"
                    fullWidth={true} formClassName={classes.one_three_input}>
                    <SelectInput optionText="countryName" />
                </ReferenceInput>

                <ReferenceInput label="Organization State" source="organizationStateId" reference="states"
                    fullWidth={true} formClassName={classes.two_three_input}>
                    <SelectInput optionText="stateName" />
                </ReferenceInput>
                <ReferenceInput label="Organization City" source="organizationCityId" reference="cities"
                    fullWidth={true} formClassName={classes.last_three_input}>
                    <SelectInput optionText="cityName" />
                </ReferenceInput>
                <TextInput fullWidth={true}  multiline source="remarks" label="Remarks" />
            </SimpleForm>
        </Edit>
    );
}

import React from 'react';
import {
    List, Datagrid, TextField, DateField,
    ReferenceField, ImageField, EmailField,Filter, TextInput, ReferenceInput, AutocompleteInput,
    NumberField, BooleanField, EditButton
} from 'react-admin';

const UserFilter = props => (
    <Filter {...props}>
        <TextInput label="Username" source="username~like" alwaysOn />
        <TextInput label="Email" source="email~like" alwaysOn />
        <TextInput label="Mobile" source="mobile~like" alwaysOn />
    </Filter>
);
export const UserList = props => {
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }
    return (
        <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false}filters={<UserFilter />} >
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
    
            <TextField source="username" />
            <TextField source="initials" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <EmailField source="email" />
            <TextField source="mobile" />
            <ReferenceField label="User Role" source="roleid" reference="roletypes">
                <TextField source="rolename" />
            </ReferenceField>
            <BooleanField source="status" label="Is Active" />
        </Datagrid>
    </List>
)};
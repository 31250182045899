import React, { useState, useEffect, useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import * as apiClient from "../../apiClient";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import { RoleTypesEditModule } from "./RoleTypesEditModule";


const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 15,
    },
}));


export const MyCheckbox = (props) => {
    console.log("Checkbox Regenerated");
    let [checked, setChecked] = useState(true);
    let right = props.right;
    let category = props.category;
    let name = props.name;
    const handleOnChange = (e) => {
        if (checked) {
            setChecked(false);
            props.onChange(0, right, category, name);
        } else {
            props.onChange(1, right, category, name);
            setChecked(true);
        }
    }
    useEffect(() => {
        if (props.value == 1)
            setChecked(true);
        else
            setChecked(false);
    }, [props.value]);
    return (
        <FormControlLabel
            control={<Checkbox checked={checked}
                onChange={handleOnChange}
                name={name} />}
            label={name}
        />
    )
}

export const RoleDetailsForm = (props) => {
    const classes = useStyles();
    let rights = props.rights;
    let categories = props.categories;
    let record = props.record;

    //console.log("Rerender : ");
    //console.log(rights);

    const TopLevelSelectAllItems = (e, category) => {
        console.log(category);
    }
    const flagAllrights = (e, item, category) => {
        let localRights = { ...rights };
        let mod = localRights[category];
        let modArray = [...mod];
        let index = modArray.findIndex((inner, index) => inner.Module == item.Module);
        modArray[index] = item;
        localRights[category] = modArray;
        props.setRights(localRights);
        console.log("flagAllrights - calling parent");
        console.log(localRights);
        // }
    }
    const selectUnselectItem = (value, item, category, name) => {
        let localRights = rights;
        let mod = localRights[category];
        let modArray = [...mod];
        let index = modArray.findIndex(inner => inner.Module == item.Module);
        item[name] = value;
        modArray[index] = item;
        localRights[category] = modArray;
        props.setRights(localRights);
        console.log("selectUnselectItem - calling parent");
        console.log(localRights);
    }
    const getRolesForRoleId = () => {
        apiClient.getfunctionbyroletype(record.id).then((res) => {
            var outObject = res.reduce(function (a, e) {
                let estKey = (e['ModuleCategory']);
                (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
                return a;
            }, {});
            var keys = Object.keys(outObject);
            props.setCategories(keys);
            props.setRights(outObject);
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        getRolesForRoleId();
    }, []);
    return (
        <div className="role-details">
            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}>
                {categories && categories.length > 0 && categories.map((item, index) => {
                    return (
                        <AccordionItem key={index} uuid={index}>
                            <div className="header-parent">
                                <AccordionItemHeading className="accord-header">
                                    <AccordionItemButton>
                                        <div className="item-caption">{item}</div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                {/* <div className="item-switch"><Switch className="right-toggle"
                                    onChange={(e) => { TopLevelSelectAllItems(e, item); }}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                /></div> */}
                            </div>
                            <AccordionItemPanel>
                                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19]}>
                                    {rights && rights[item] && rights[item].map((inner, rightIndex) => {
                                        return (
                                            <RoleTypesEditModule key={rightIndex} flagAllrights={flagAllrights} selectUnselectItem={selectUnselectItem} right={inner} category={item} rightIndex={rightIndex} />
                                            // <AccordionItem key={rightIndex} uuid={rightIndex}>
                                            //     <div className="header-parent">
                                            //         <AccordionItemHeading className="accord-header">
                                            //             <AccordionItemButton>
                                            //                 <div className="item-caption">{inner.Module}</div>
                                            //             </AccordionItemButton>
                                            //         </AccordionItemHeading>
                                            //         <div className="item-switch"><Switch className="right-toggle"
                                            //             onChange={(e) => { selectAllItems(e, inner, item); }}
                                            //             name="checkedA"
                                            //             inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            //         /></div>
                                            //     </div>
                                            //     <AccordionItemPanel>
                                            //         <div className="right-option">
                                            //             <FormGroup row>
                                            //                 <MyCheckbox onChange={selectUnselectItem} right={inner} category={item} name="Read" value={inner.Read} />
                                            //                 <MyCheckbox onChange={selectUnselectItem} right={inner} category={item} name="Create" value={inner.Create} />
                                            //                 <MyCheckbox onChange={selectUnselectItem} right={inner} category={item} name="Modify" value={inner.Modify} />
                                            //                 <MyCheckbox onChange={selectUnselectItem} right={inner} category={item} name="Delete" value={inner.Delete} />
                                            //                 <MyCheckbox onChange={selectUnselectItem} right={inner} category={item} name="Export" value={inner.Export} />
                                            //             </FormGroup>
                                            //         </div>
                                            //     </AccordionItemPanel>
                                            // </AccordionItem>
                                        )
                                    })}
                                </Accordion>
                            </AccordionItemPanel>
                        </AccordionItem>
                    )
                })}
            </Accordion>
        </div>
    );
}

export const RoleTypesEdit = props => {
    const history = useHistory();
    const [rights, setRights] = useState(null);
    const [categories, setCategories] = useState([]);

    const handleSubmit = (formdata) => {
        console.log(formdata);
        console.log(rights);
        // let date = (formdata.date) ? moment(formdata.date).format() : null;

        let finalData = [];
        categories.forEach(item => {
            finalData = finalData.concat(rights[item]);
        });

        let data = {
            role_id: formdata.id,
            role_name: formdata.roleName,
            ownrecords: formdata.ownrecords,
            issalarytab: formdata.issalarytab,  
            rights: finalData
        }
        let role_data = {
            id: formdata.id,
            roleName: formdata.roleName,
            ownrecords: formdata.ownrecords,
            issalarytab: formdata.issalarytab,
            createdBy: formdata.createdBy,
            createdDate: formdata.createdDate,
            modifyBy: formdata.createdBy,
            modifyDate: formdata.createdDate
        }
        apiClient.updateRoleType(role_data.id,role_data);
        apiClient.updateRolerights(data).then(res => {
            if (res.result == "success") {
                history.push("/role-types");
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <Edit
            {...props} title="Role Types" redirect={false}
            successMessage="Role Type Updated" undoable={false}>
            <SimpleForm redirect="list"
                onSubmit={handleSubmit}
                variant="outlined">
                <TextInput validate={required()} source="roleName" label="Role Name" />
                <BooleanInput validate={required()} fullWidth={true} source="ownrecords" label="Can see own employee record only" />
                <BooleanInput initialValue={false} fullWidth={true} source="issalarytab" label="Can see employee salary tab" />
                {<RoleDetailsForm rights={rights} categories={categories} setRights={setRights} setCategories={setCategories} />}
            </SimpleForm>
        </Edit>
    )
};

import React, { FC } from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    Edit,
    EditButton,
    FormTab,
    NullableBooleanInput,
    NumberField,
    ReferenceManyField,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import NbItemsField from '../orders/NbItemsField';
import ProductReferenceField from '../products/ProductReferenceField';
import StarRatingField from '../reviews/StarRatingField';
import FullNameField from './FullNameField';
import SegmentsInput from './SegmentsInput';
import { styles } from './VisitorCreate';
import { Customer, FieldProps } from '../types';

const useStyles = makeStyles(styles);

const VisitorTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const VisitorEdit = (props: any) => {
    const classes = useStyles({});
    return (
        <Edit title={<VisitorTitle />} {...props}>
            <TabbedForm>
                <FormTab label="resources.customers.tabs.identity">
                    <TextInput
                        source="first_name"
                        formClassName={classes.first_name}
                    />
                    <TextInput
                        source="last_name"
                        formClassName={classes.last_name}
                    />
                    <TextInput
                        type="email"
                        source="email"
                        validation={{ email: true }}
                        fullWidth={true}
                        formClassName={classes.email}
                    />
                    <DateInput source="birthday" />
                </FormTab>
                <FormTab
                    label="resources.customers.tabs.address"
                    path="address"
                >
                    <TextInput
                        source="address"
                        formClassName={classes.address}
                        multiline={true}
                        fullWidth={true}
                    />
                    <TextInput
                        source="zipcode"
                        formClassName={classes.zipcode}
                    />
                    <TextInput source="city" formClassName={classes.city} />
                </FormTab>
                <FormTab label="resources.customers.tabs.orders" path="orders">
                    <ReferenceManyField
                        addLabel={false}
                        sort={{ field: 'date', order: 'DESC' }}
                        reference="commands"
                        target="customer_id"
                    >
                        <Datagrid>
                            <DateField source="date" />
                            <TextField source="reference" />
                            <NbItemsField />
                            <NumberField
                                source="total"
                                options={{ style: 'currency', currency: 'USD' }}
                            />
                            <TextField source="status" />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                <FormTab
                    label="resources.customers.tabs.reviews"
                    path="reviews"
                >
                    <ReferenceManyField
                        addLabel={false}
                        sort={{ field: 'date', order: 'DESC' }}
                        reference="reviews"
                        target="customer_id"
                        fullWidth
                    >
                        <Datagrid filter={{ status: 'approved' }}>
                            <DateField source="date" />
                            <ProductReferenceField />
                            <StarRatingField />
                            <TextField
                                source="comment"
                                cellClassName={classes.comment}
                            />
                            <EditButton style={{ padding: 0 }} />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                <FormTab label="resources.customers.tabs.stats" path="stats">
                    <SegmentsInput />
                    <NullableBooleanInput source="has_newsletter" />
                    <DateField
                        source="first_seen"
                        style={{ width: 128, display: 'inline-block' }}
                    />
                    <DateField
                        source="latest_purchase"
                        style={{ width: 128, display: 'inline-block' }}
                    />
                    <DateField
                        source="last_seen"
                        style={{ width: 128, display: 'inline-block' }}
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default VisitorEdit;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import OrderIcon from '@material-ui/icons/AttachMoney';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FlagIcon from '@material-ui/icons/Flag';
import { withRouter } from 'react-router-dom';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import GroupIcon from '@material-ui/icons/Group';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SpeedIcon from '@material-ui/icons/Speed';
import TimerIcon from '@material-ui/icons/Timer';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ImageIcon from '@material-ui/icons/Image';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AccountBoxIcon from '@material-ui/icons/AccountBox';


import {
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
} from 'react-admin';
import SubMenu from './SubMenu';

const MenuIcon = ({menuStr}) => {
    const i = "<i class='fas fa-ambulance'></i>";
    return <span dangerouslySetInnerHTML={{
        __html: menuStr
      }}></span>
}
export const MenuContainer = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { onMenuClick, sidebarIsOpen, dense, caption, items } = props;

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    return <SubMenu
        handleToggle={() => handleToggle()}
        isOpen={isOpen}
        sidebarIsOpen={sidebarIsOpen}
        name={caption}
        dense={dense}
        icon={<AccountBoxIcon />}
    >
        {items && items.map((item, index) => {
            if (item.Read == 1) {
               // console.log(item);
                return (
                    <MenuItemLink key={index}
                        to={item.NavigateUrl}
                        primaryText={item.Module}
                        // leftIcon={<AccountCircleIcon />}
                        leftIcon={<MenuIcon menuStr={item.Icon} />}
                        onClick={onMenuClick}
                        dense={dense}
                    />
                )
            } else {
                return null
            }

        })}
    </SubMenu>;
}
export default MenuContainer;